import React, { useEffect,useCallback, useRef,useState } from 'react';
import PropTypes from 'prop-types';
import { getReviewAndRatingId} from '../../adapters/helpers/Utils';
import { UserQuoteConstants, PrePurchaseConstants, PostPurchaseConstants} from '../../adapters/helpers/Constants';
import ProductSEO from './ProductSchema';
import { getSearchQueryParameter } from '../Janrain/Janrain/janrainForms/Janrain.helpers';

    function handleFindElement(){
        setTimeout(()=>{
            let buttonElement = document.getElementById('ratings-summary');
            if(buttonElement){
                buttonElement.setAttribute('role','button')
            }
            var activeListItems=document.getElementById('bv-dropdown-select-reviews-sortby')
            Array.isArray(activeListItems )&& activeListItems.length>0 && activeListItems.setAttribute('role','listbox');
         
            let menuitem=document.getElementsByClassName('bv-focusable')||'';
            Array.isArray(menuitem) && menuitem.length > 0 && menuitem.forEach((menuitem)=>{
            menuitem.setAttribute('role','option')
          })
         
          let reviewInput=document.getElementById('bv-text-field-search-contentSearch1');
        Array.isArray(reviewInput) && reviewInput.length>0 &&reviewInput.setAttribute('aria-label','Search topics and reviews')
    
            if(typeof window!='undefined')  {
                let optionElement=window.document.getElementById('bv-dropdown-select-reviews');
                if(optionElement) {
                optionElement.setAttribute('role','option')
                }         
            }
                       
        },5000)
}
function CustomerReview(props) {
    const ratingRefernce = useRef()   
    let [rating, setRating] = useState();
    let [review, setTotalReview] = useState();

    const reviewAndRatingId = getReviewAndRatingId(props?.product?.productOverview);
    
    useEffect(() => {
        handleRatingandReviews();
    }, []);
    const handleRatingandReviews=()=>{
        const requestOptions = {
        method: 'GET',
        };
        
        let RnRURL = process.env.BAZAAR_VOICE_RNR?.replace('{reviewAndRatingId}', reviewAndRatingId);
            
        fetch(RnRURL, requestOptions).then(async (response) => {
            if(response.ok) {
                const output= await response.json();
                return output;
            }
        }).then(async (output) => {
                setRating(output?.reviewSummary?.primaryRating?.average)
                setTotalReview(output?.reviewSummary?.numReviews)

        })
        .catch((error) => {
        console.error('Bazaar Voice RNR API: ', error);
        });
    }
  
   useEffect(() => {
        document.addEventListener('DOMContentLoaded', handleFindElement());
        return () => document.removeEventListener('DOMContentLoaded', handleFindElement());
    },[]); 
    useEffect(() => {
        const writeAReviewQueryParamValue = getSearchQueryParameter(PrePurchaseConstants.writeAReviewQueryParam);
        if(writeAReviewQueryParamValue === PrePurchaseConstants.writeAReviewQueryParamValue) {
            setTimeout(() => {
                const warCta = document.getElementsByClassName(PostPurchaseConstants.WAR_POP_ID).length > 0 && document.getElementsByClassName(PostPurchaseConstants.WAR_POP_ID)[0]
                warCta && warCta.click();
            }, PrePurchaseConstants.WAR_CTA_CLICK_TIMEOUT);
        }
    }, []);

    return (
        <>
            { props.isDisplay &&
                <div className='ob-customer-review'>
                   
                    {reviewAndRatingId &&
                        <div className='ob-customer-review-container' id='BVCustomerReviewContiner'>
                            <h2 className='ob-customer-review-title'>{props.title}</h2>

                            <div ref={ratingRefernce} data-bv-show={UserQuoteConstants.ratingSummary} data-bv-product-id={reviewAndRatingId}></div>

                            <div data-bv-show={UserQuoteConstants.reviews} data-bv-product-id={reviewAndRatingId}></div>

                            <div data-bv-show={UserQuoteConstants.questions} data-bv-product-id={reviewAndRatingId}></div>
                        </div>
                    }
                </div>
            }
            <ProductSEO       
                productDetails={props?.product?.productOverview?.fields}
                rating={rating}
                review={review}
                                             
            /> 
        </>
    )
}

CustomerReview.propTypes = {
    product: PropTypes.object,
    productName: PropTypes.string,
    title: PropTypes.string,
    isDisplay: PropTypes.bool,
}


export default CustomerReview
