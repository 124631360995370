import {AnalyticsConstants} from '../../adapters/helpers/ConstantsGA';
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import ImageFactory from '../../adapters/cloudinary/ImageFactory';
import Head from 'next/head';

class HomeVideoPlayer extends Component {

    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.videoPlayer = null;
        this.videoplay = this.onVideoPlay.bind(this);
        this.videoPause = this.onVideoPause.bind(this);
        this.videoEnd = this.onVideoEnd.bind(this);
        this.video = props.video;
        this.state = {
            video: null
        }
    }

    getVideo() {
        return this.video;
    }

    getVideoRef() {
        return this.videoRef?.current;
    }

    getVideoCustomID(string) {
        let videoCustomId = this.video.assetId;
        videoCustomId = videoCustomId.split('/');

        return string + '-' + videoCustomId[videoCustomId.length - 1];
    }

    getVideoPlayerRef() {
        return this.videoPlayer;
    }

    componentDidMount() {
        if (this.videoIsDefined()) {
            this.videoRef?.current?.play();
            this.props?.onVideoPlaying(true);
            this.videoRef?.current?.parentNode?.classList.add('vjs-playing')
        }
    }

    onVideoPause() {
        this.videoRef?.current?.parentNode?.classList.add('vjs-paused')
        this.setAnalyticsPlayPauseTags();
    }

    onVideoEnd(){
        if(this.props?.onVideoPlaying) {
            this.props?.onVideoPlaying(false);
        }
        this.videoRef?.current?.parentNode?.classList.remove('vjs-playing')
        this.videoRef?.current?.parentNode?.classList.add('vjs-ended')
    }
    onVideoPlay() {
        this.videoRef?.current?.parentNode?.classList.add('vjs-playing')
        this.setAnalyticsPlayPauseTags();
    }

    onVideoMuted() {
        this.setAnalyticsMuteUnmuteTags();
    }

    onVideoUnMuted() {
        this.setAnalyticsMuteUnmuteTags();
    }

    onVideoFullScreen() {
        this.setAnalyticsFullScreenTags();
    }

    setAnalyticsPlayPauseTags() {
        if(this.videoRef.current.parentNode) {
            let isVideoPaused = this.videoRef?.current?.parentNode?.classList.contains('vjs-paused');
            let playPauseButton = document.getElementsByClassName('ob-contentBlock-video-controls').length > 0 && document.getElementsByClassName('ob-contentBlock-video-controls')[0].querySelector('button.vjs-play-control');
            this.setElementAnalytics(playPauseButton, 'event-video-play', AnalyticsConstants.videoPlayText, AnalyticsConstants.videoPauseText, !isVideoPaused);
        }
    }

    setAnalyticsMuteUnmuteTags() {
        if(this.videoRef.current.parentNode) {
            let isVideoMuted = this.videoRef?.current.getAttribute('muted');
            let muteUnmuteButton = this.videoRef?.current.parentNode?.querySelector('button.vjs-mute-control');
            this.setElementAnalytics(muteUnmuteButton, 'event-button-click', AnalyticsConstants.videoUnmuteText, AnalyticsConstants.videoMuteText, !isVideoMuted);
        }
    }

    setAnalyticsFullScreenTags() {
        if(this.videoRef.current.parentNode) {
            let isVideoFullScreen = this.videoRef?.current?.parentNode?.classList.contains('vjs-fullscreen');
            let fullscreenButton = this.videoRef?.current?.parentNode.querySelector('button.vjs-fullscreen-control');
            this.setElementAnalytics(fullscreenButton, 'event-button-click', AnalyticsConstants.videoFullscreenOffText, AnalyticsConstants.videoFullscreenOnText, !isVideoFullScreen);
        }
    }

    setAnalyticsCaptionsTags() {
        if(this.videoRef.current.parentNode) {
            let isCaptionsVisible = this.videoRef?.current?.parentNode.querySelector('div.vjs-text-track-cue');
            let captionsButton = this.videoRef?.current?.parentNode.querySelector('button.vjs-subs-caps-button');
            this.setElementAnalytics(captionsButton, 'event-button-click', AnalyticsConstants.videoCloseCaptionsOffText, AnalyticsConstants.videoCloseCaptionsOnText, !isCaptionsVisible);
        }
    }

    setElementAnalytics(element, className, dataActionDetail, dataActionDetailAlternative = '', isAlternative = false) {
        if (element) {
            if (!element.classList.contains(className)) {
                element.classList.add(className);
            }

            if (!isAlternative) {
                element.setAttribute('data-action-detail', dataActionDetail);
            } else {
                element.setAttribute('data-action-detail', dataActionDetailAlternative);
            }
        }
    }

    videoIsDefined() {
        return (this.video && this.video.assetId);
    }

    renderTranscript() {
        return <div className="video-player-transcript visuallyhidden">{this.video.transcript}</div>
    }

    render() {
        const isVideoPosterImagePreload = this.props?.video?.isVideoPosterImagePreload;
        const videoSettings = this.props?.video?.videoSettings?.fields;
        let poster = ImageFactory.buildContentfullImageUrlByHeight(this.props.video?.imageAsset?.fields?.file?.url,videoSettings.height);
        return (
            <Fragment>
                <Head>
                    {isVideoPosterImagePreload && <link rel='preload' as='image' href={poster} />}
                </Head>
                <div className="ob-video-player">
                    <video
                        muted
                        poster={isVideoPosterImagePreload ? poster : null}
                        playsInline                   
                        src={this.props.video?.asset?.fields?.file?.url}
                        height={videoSettings.height}
                        width={videoSettings.width}
                        ref={this.videoRef}
                        onPlay={this.videoplay}
                        onPause={this.videoPause}
                        onEnded={this.videoEnd}
                    />
                    {this.renderTranscript()}
                </div>
            </Fragment>
        )
    }
}

HomeVideoPlayer.propTypes = {
    video: PropTypes.object,
    onVideoPlaying: PropTypes.func,
};

export default HomeVideoPlayer
