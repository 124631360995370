import React, { useMemo,useEffect } from 'react'
import PropTypes from 'prop-types';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { recursivelyRemoveSysFromEntry } from '../../adapters/helpers/Contentful';
import Block from '../../adapters/helpers/Block';
import Component from '../../adapters/helpers/entity/Component';
import { RichTextConstants } from '../../adapters/helpers/Constants'
import {addLabel} from '../../adapters/helpers/Utils'
export default function RichText(props) {
  const {document, extraAttributes} = props;

  const entityRichTextBlock = extraAttributes?.richTextBlock || [];

  const richTextBlock = new Component(entityRichTextBlock);
  const text = document?.fields?.text || richTextBlock.getFieldValue(RichTextConstants.text);
  const classNames = document?.fields?.classNames || richTextBlock.getFieldValue(RichTextConstants.classNames);
  const anchorId = document?.fields?.anchorId || richTextBlock.getFieldValue(RichTextConstants.anchorId);
  const propClassname = props.className || '';

  const options = useMemo(() => {
    const handleEmbedNode = (node => {
      const target = recursivelyRemoveSysFromEntry(node?.data?.target);
      const block = new Block(target);
      
      const {
        extraAttributes,
        onClickCallback,
        customEvent
      } = props;

      return block.renderBlockFromDocument(extraAttributes,
          onClickCallback,
          customEvent);
    } );
    
    const handleHyperLink = (node, children) => {
      const { data,content } = node;
      return <a href={data?.uri} aria-label={content ? content?.[0]?.value : ''} title={children ? `${children}` : ''} >{children}</a>
    };

    return ({
      renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: handleEmbedNode,
        [INLINES.EMBEDDED_ENTRY]: handleEmbedNode,
        [INLINES.HYPERLINK]: handleHyperLink,
      }
    });
  }, [props]);

  useEffect(() => {
    if(typeof window !== 'undefined') {
      var ele = window.document.getElementsByClassName('ob-series-selector__selected-highlights-item');
      var prodEle = window.document.getElementsByClassName('ob-product-highlights__highlights-list-item');
      if(ele) {
        addLabel(ele);
      }

      if(prodEle) {
        addLabel(prodEle);
      }  
}
  },[]);
  const component = (text) ?  documentToReactComponents(text, options) : null;

  return (<div className={classNames + ` ${propClassname}`} id={anchorId} >
    {component}
  </div>);
}

RichText.propTypes = {
  className: PropTypes.string,
  document: PropTypes.object,
  extraAttributes: PropTypes.any
};
