import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Block from '../../adapters/helpers/Block';
import Image from '../Image/Image';
import { ZoneConstants } from '../../adapters/helpers/Constants';
import { ContentConstants, Constants, ClicConstant} from '../../adapters/helpers/Constants';
import Button from '../Button/Button';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import ClicRegister from '../ClicRegister/ClicRegister'

function ClicCarousel(props) {
    const { isSmall, languageCode } = props
    const [isRegisterModelOpen, setIsRegisterModelOpen] = useState(false)
    const block = new Block(props);
    const valueOf = (name) => {
        let value = undefined;
        if (name && block && block.getFieldValue(name) !== undefined) {
            value = block.getFieldValue(name);
        }
        return value;
    };

    const [activeSlideIndex, setActiveSlideIndex] = useState(0)
    const backgroundImage = block.getFieldValue(ZoneConstants.backgroundImage);
    const registerPopup = block.getFieldValue(Constants.registerPopup);
    const name = block.getFieldValue(Constants.name);
    const doc = block.getDocument();
    const activeSlide = doc.fields && doc.fields.carouselSlides ? doc.fields.carouselSlides[activeSlideIndex] : {}
    const { fields: { title, description, devicesConfigurations } } = activeSlide

    const handleCarouselClick = (selectedIndex) => {
        setActiveSlideIndex(selectedIndex)
    }

    const handleRegisterOpen = () => {
        setIsRegisterModelOpen(true)
    }
    const handleRegisterClose = () => {
        setIsRegisterModelOpen(false)
    }

    const btnContent = <Button
        whiteTheme={false}
        borderTheme={false}
        borderThemeBlue={false}
        invertedTheme={false}
        tag="a"
        className={'event_external_link'}
        dataActionDetail={valueOf(ContentConstants.callToActionLink)}
        href={valueOf(ContentConstants.callToActionLink)}
        onClick={handleRegisterOpen}
    >
        {valueOf(ContentConstants.callToActionLabel)}
    </Button>;

    const mainAssetIndex = isSmall ? ClicConstant.one : ClicConstant.zero
    const mainasset = devicesConfigurations && devicesConfigurations[mainAssetIndex] && devicesConfigurations[mainAssetIndex].fields && devicesConfigurations[mainAssetIndex].fields.mainAsset
    const isClicTopCarousel = name === ClicConstant.clicTopCarousel
    const topCarouselTitle = block.getFieldValue(Constants.title);
    const topCarouselDescription = block.getFieldValue(Constants.description);
    if (isClicTopCarousel) {
        const inActiveThubmIcon = doc?.fields?.carouselSlides[0]?.fields?.iconDataMobile
        const activeThubmIcon = doc?.fields?.carouselSlides[1]?.fields?.iconDataMobile
        return <React.Fragment>
            {isSmall ?
                <section className='top-clic-carousel-wrapper'>
                    <div className='carousel-title-section'>
                        <h2 className='carousel-title'>{topCarouselTitle}</h2>
                        <p className='carousel-description'>{topCarouselDescription}</p>
                    </div>
                    <div className='carousel-wrapper-mobile'>
                        <div className='content-wrapper-top'>
                            <div className='content-image'>
                                <Image image={mainasset}></Image>
                            </div>
                            <div className='content-row'>
                                <h2 className='slide-title' role='presentation' aria-label='title-btn'>{activeSlide.fields && activeSlide.fields.title}</h2>
                                <p className='slide-description'>{activeSlide.fields && activeSlide.fields.description}</p>
                            </div>
                        </div>
                        <div className='content-wrapper-bottom'>
                            {doc?.fields?.carouselSlides?.map((img, index) => {
                                return <img key={index} alt={'Thump icon'}
                                    src={index === activeSlideIndex ? activeThubmIcon : inActiveThubmIcon}
                                    onClick={() => handleCarouselClick(index)} role='presentation' />
                            })}
                        </div>
                    </div>
                </section>
                : <section className='top-clic-carousel-wrapper'>
                    <div className='carousel-title-section'>
                        <h2 className='carousel-title'>{topCarouselTitle}</h2>
                        <p className='carousel-description'>{topCarouselDescription}</p>
                    </div>
                    <div className='carousel-wrapper-desktop'>
                        <div className='content-wrapper-left'>
                            {doc?.fields?.carouselSlides?.map((slideIconData, slideIndex) => {
                                const slideIconTitle = slideIconData?.fields?.title
                                const slideIconDescription = slideIconData?.fields?.description
                                return <div key={slideIndex} className={`content-row ${(activeSlideIndex === slideIndex ? 'active' : '')}`}>
                                    <div className='content-column'>
                                        <button onClick={() => handleCarouselClick(slideIndex)}>
                                            <img alt={slideIconTitle} src={slideIconData.fields.iconDataDesktop} />
                                        </button>
                                    </div>
                                    <div className='content-column'>
                                        <h2 role='presentation' aria-label='title-btn' className='slide-title' onClick={() => handleCarouselClick(slideIndex)}>{slideIconTitle}</h2>
                                        <p className='slide-description'>{slideIconDescription}</p>
                                    </div>
                                </div>
                            })}
                        </div>
                        <div className='content-wrapper-right'><Image image={mainasset}></Image> </div>
                    </div>
                </section>
            }
            <ClicRegister handleClose={handleRegisterClose} languageCode={languageCode}
                isModelOpen={isRegisterModelOpen} registerPopup={registerPopup} />
        </React.Fragment>
    } else {
        const mainasset = devicesConfigurations && devicesConfigurations[0]?.fields?.mainAsset
        return (<React.Fragment>
            <section className='clic-carousel-wrapper'>
                <Image image={backgroundImage} >
                    <h1 className='title'>{title}</h1>
                    <p className='description'>{description}</p>
                    <Image image={mainasset} />
                    <div className='btn-icon-wrapper'>
                        {doc.fields && doc.fields.carouselSlides.map((slideIconData, slideIndex) => {
                            const slideIconTitle = slideIconData.fields && slideIconData.fields.title
                            return <div key={slideIndex} className='btn-icon'>
                                <div className='icon'>
                                    <button onClick={() => handleCarouselClick(slideIndex)} className={(activeSlideIndex === slideIndex) ? 'active-slide' : ''} >
                                        <img alt={slideIconTitle} src={`${slideIconData.fields.iconDataDesktop}`} />
                                    </button>
                                </div>
                                <div>
                                    <br />
                                    <label className='btn-icon-label'>{slideIconTitle}</label>
                                </div>
                            </div>
                        })}
                    </div>
                    <div className='bottom-cta'>
                        {btnContent}
                    </div>
                </Image>
            </section>
            <ClicRegister handleClose={handleRegisterClose} languageCode={languageCode}
                isModelOpen={isRegisterModelOpen} registerPopup={registerPopup} />
        </React.Fragment>
        )
    }
}

export default mediaQueryHOC(ClicCarousel)
ClicCarousel.propTypes = {
    isSmall: PropTypes.bool,
};