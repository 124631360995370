import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Block from '../../adapters/helpers/Block';
import Image from '../Image/Image';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import Modal from 'react-modal';
import Icon from '../Icon/Icon';
import { YoutubeVideoConstants, SpotlightContentVideoConstants, ClicConstant } from '../../adapters/helpers/Constants';

function ClicVideo(props) {
    const { isSmall } = props;
    const [isModelOpen, setModelOpen] = useState(false)
    const block = new Block(props);
    const doc = block.getDocument();

    const handleCarouselClick = (e) => {
        setModelOpen(true)
    }

    const handleVideoClose = () => {
        setModelOpen(false)
    }
    const customModalStyles = {
        overlay: {
            backgroundColor: 'rgba(0,0,0,.7)',
            zIndex: 200
        },
        content: {
            border: 'none',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            fontSize: '1.4rem',
            lineHeight: '2.1rem',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '123.6rem',
            width: '100%',
            backgroundColor: 'transparent'
        }
    };
    const { devicesConfigurations, title, description, playIcon, videoId } = doc.fields
    const bgAssetIndex = isSmall ? 1 : 0
    const bgAsset = devicesConfigurations && devicesConfigurations[bgAssetIndex]?.fields?.mainAsset
    return (<React.Fragment>
        <section className='clic-video-wrapper'>
            <Image image={bgAsset} >
                <h2 className='title'>{title}</h2>
                <p className='description'>{description}</p>
                <div role='presentation' aria-label={ClicConstant.ariaLabel}  className='play-icon' onClick={handleCarouselClick}>
                    <Image image={playIcon} />
                </div>
            </Image>
        </section>
        {videoId &&
            <Modal
                isOpen={isModelOpen}
                onRequestClose={handleVideoClose}
                closeTimeoutMS={ClicConstant.closeTimeoutMS}
                style={customModalStyles}>
                <div className='video-block'>
                    <button className="ob-modal-close no-shadow video-close-icon"
                        onClick={handleVideoClose}
                        aria-label={'close'}>
                        <Icon name={SpotlightContentVideoConstants.close} size="2" color="#fff" />
                    </button>
                    <div className="ob-youtube-video-block video-block-inner" style={{ paddingBottom: (ClicConstant.videoBlockValue / ClicConstant.aspectRatio).toFixed(3) + YoutubeVideoConstants.percent }}>
                        <iframe title={title} src={`${YoutubeVideoConstants.url}${videoId}?autoplay=1`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>
            </Modal>
        }
    </React.Fragment>
    )
}

export default mediaQueryHOC(ClicVideo)
ClicVideo.propTypes = {
    isSmall: PropTypes.bool,
};