import React from 'react';
import PropTypes from 'prop-types';
import {YoutubeVideoConstants} from '../../adapters/helpers/Constants';
const DEFAULT_ASPECT_RATIO = 16/9;

export default function YoutubeVideo({document}) {
    const videoId = document?.fields?.videoId || document?.videoId;
    const aspectRatio = document?.fields?.aspectRatio || document?.aspectRatio || DEFAULT_ASPECT_RATIO;
    const classNames = document?.fields?.classNames || document?.classNames || '';
    const captionUrl = document?.fields?.captionText || document?.captionText || process.env.DEFAULT_CAPTION;
    const name = document?.fields?.name || document?.name;
    const description = document?.fields?.description || document?.description;
    const thumbnailurl = document?.fields?.thumbnailUrl || document?.thumbnailUrl;
    const UploadDate = document?.fields?.uploadDate || document?.uploadDate;
    const duration = document?.fields?.duration || document?.duration;
    const width = document?.fields?.width || document?.width;
    const height = document?.fields?.height ||  document?.height;
    return (<>
        {!!videoId &&
            <div className={classNames}>
                <div className="ob-youtube-video-block" style={{paddingBottom: (100/ aspectRatio).toFixed(3) + YoutubeVideoConstants.percent}}>
                    <iframe title={YoutubeVideoConstants.title} src={`${YoutubeVideoConstants.url}${videoId}${captionUrl}&autoplay=1`} allow='autoplay'></iframe>
                </div>
            </div>
        }      
        </>)
}

YoutubeVideo.propTypes = {
    document: PropTypes.object,
}