import Block from '../../adapters/helpers/Block';
import React from 'react'
import DOMPurify from 'isomorphic-dompurify';

function HTMLBlock(props) {
    const block = new Block(props);
    const id = block.getAnchorId();
    const className = 'ob-html-block ' + block.getFieldValue('classNames');
    const text = block.getFieldValue('text');

    return (
        <div
            id={id}
            className={className}
            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(text,{ ADD_TAGS: ["iframe"] },{ ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target'] })}}
        >
        </div>
    )
}

HTMLBlock.propTypes = {

};

export default HTMLBlock;

