import PropTypes from 'prop-types';
import React from 'react';

export default function Cell({ className, ariaLabel, children, role, tabIndex }) {
    return (
        <div tabIndex={tabIndex} className={`pre-purchase-compare-chart__cell ${className}`} role={role} aria-label={ariaLabel}>{children}</div>
    );
}

Cell.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    role: PropTypes.string,
    ariaLabel: PropTypes.string,
    tabIndex: PropTypes.any
}