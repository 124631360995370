import C from 'classnames';
import Button from '../Button/Button';
import Image from '../Image/Image';
import Label from '../Label/Label';
import { getColorStyle } from '../../adapters/helpers/Utils';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import MarkdownText from '../MarkdownText/MarkdownText';
import {handleColorNameFormat} from '../../adapters/helpers/Utils'
import { stripHtml } from '../../adapters/helpers/Utils';
export default function BrushConfiguratorProduct(props) {
    const {document: doc, product, needs} = props;

    const brushSelectionLabel = doc.fields.brushSelectionLabel;
    const handleColorLabel = doc.fields.handleColorLabel;
    const inspiredLabel = doc.fields.inspiredLabel;

    const [selectedVariantIndex, setSelectedVariant] = useState(0);

    const productVariants = product.fields.productVariants;
    const productVariant = productVariants?.[selectedVariantIndex];

    const productImage = productVariant?.fields?.mainAsset || product.fields.mainAsset;

    const buyNowLabel = product.fields.buyNowLabel;
    const buyNowDataActionDetail = product.fields.buyNowDataActionDetail;

    const title = product?.fields?.shortTitle || product?.fields?.title;
    const description = product?.fields?.shortDescription || product?.fields?.description;

    const features = useMemo(
        () => {
            const a = [];

            if (needs && needs.length > 0) {
                const shortNames = needs.map(need => need.fields.shortName || need.fields.name);

                a.push({
                    fields: {
                        logoId: 'tooth',
                        description: <Label label={inspiredLabel} tokens={{needs:shortNames.join(', ')}}/>,
                    }
                });
            }

            if (product.fields.collectionFeatures) {
                a.push(...product.fields.collectionFeatures);
            }

            if (a.length > 6) {
                a.length = 6;
            }

            return a;
        },
        [ needs, product.fields.collectionFeatures ]
    );
    return (
        <div className="ob-brush-configurator-product">
            <div className="ob-brush-configurator-product__box">
                <div className="ob-brush-configurator-product__top">
                    <div className="ob-brush-configurator-product__pic">
                        {!!productImage && (
                            <Image className="ob-brush-configurator-product__pic-image"
                                image={productImage}
                            />
                        )}
                    </div>

                    <div className="ob-brush-configurator-product__info">
                        <div className="ob-brush-configurator-product__info-start">
                            <h2 className="ob-brush-configurator-product__info-name">
                                <a aria-label={stripHtml(title)} className="ob-brush-configurator-product__link" href={product.fields.slug.fields.slug}
                                   dangerouslySetInnerHTML={{__html: title}}>
                                </a>
                            </h2>

                            <MarkdownText value={description} className="ob-brush-configurator-product__info-surtitle" />

                            {!!(productVariants && productVariants.length > 0) && (
                                <div className="ob-brush-configurator-product__colors">
                                    <h3 className="ob-brush-configurator-product__colors-subheading">
                                        {handleColorLabel.fields.text}
                                    </h3>

                                    <ul className="ob-brush-configurator-product__colors-list">
                                        {productVariants.map((variant, i) => {
                                            const color = variant.fields.color;

                                            return (
                                                <li className="ob-brush-configurator-product__colors-item" key={i}>
                                                    <button aria-label={color.fields.name}
                                                        type="button"
                                                        className={C('ob-series__color', {
                                                            ['ob-series__color--large-mobile']: productVariants.length <= 2,
                                                            ['ob-series__color--active']: i === selectedVariantIndex
                                                        })}
                                                        style={getColorStyle(color)}
                                                        onClick={() => setSelectedVariant(i)}
                                                        aria-pressed={i === selectedVariantIndex ? true : false}
                                                    >
                                                        {color.fields.name}
                                                    </button>
                                                    {color.fields? ( <div className='color-container-feature'>
                                                <label className={ i === selectedVariantIndex ? 'ob-color-name-highlighted' : 'ob-color-name' }>{handleColorNameFormat(color.fields.name)}</label>
                                                </div>) : ''}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            )}
                        </div>

                        <div className="ob-brush-configurator-product__info-end">
                            {!!buyNowLabel && (
                                <div className="ob-brush-configurator-product__buy">
                                    <Button className="ob-brush-configurator-product__buy-btn event_button_click" dataActionDetail={buyNowDataActionDetail?.fields?.text} tag="a" href={product.fields.slug.fields.slug}>
                                        {buyNowLabel.fields.text}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {(features.length > 0) && (
                    <div className="ob-brush-configurator-product__features">
                        {!!brushSelectionLabel && (
                            <h3 className="ob-brush-configurator-product__features-heading">
                                {brushSelectionLabel.fields.text}
                            </h3>
                        )}

                        <ul className="ob-brush-configurator-product__features-list">
                            {features.map((feature, i) => (
                                <li className="ob-brush-configurator-product__features-item" key={i}>
                                    <span className={`ob-brush-configurator-product__features-icon ${feature.fields.logoId ? 'icon-' + feature.fields.logoId : ''}`} aria-hidden="true" />

                                    <span className="ob-brush-configurator-product__features-text">
                                        {feature.fields.description}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
}

BrushConfiguratorProduct.propTypes = {
    document: PropTypes.any,
    product: PropTypes.any,
    needs: PropTypes.any,
};
