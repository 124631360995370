
import React from 'react';
import { connectPagination } from 'react-instantsearch-dom';
import { AnalyticsConstants } from '../../../adapters/helpers/ConstantsGA';
import { algoliaReducerConstants } from './../../../adapters/helpers/Constants'


const Pagination = ({ refine,state,dispatch }) => {
 
let   nbPages = state.nbPages;

const getPageNumbers = () => {
  const MAX_PAGE_NUMBERS_VISIBLE = 5;
    let {pagesToIncrement, pagesToDecrement} = getPageToIncrementAndDecrement();

    let pageIndexes = getPageIndexes(pagesToIncrement, pagesToDecrement);
  

    if (pageIndexes.length === MAX_PAGE_NUMBERS_VISIBLE) {
        if (pageIndexes[pageIndexes.length - 1] < nbPages) {
            pageIndexes[pageIndexes.length - 1] = -1;
            pageIndexes.push(nbPages);
        }
        if (pageIndexes[0] > 1) {
            pageIndexes[0] = -1;
            pageIndexes.unshift(1);
        }
    }
    const index = pageIndexes.indexOf(0);
    if (index > -1) {
        pageIndexes.splice(index, 1);
    }
    return <div className={'ob-paging__page-numbers'}>
        {pageIndexes.map((page, index) => {
      
            if (page <= 0) {
            
                return <span key={'ob-paging__page-spacer-' + index}>...</span>
            } else {
                return <button className={`event_button_click ob-paging__page-number${page === state?.pageNoClicked ? ' active' : ''}`}
                                   data-action-detail={page}
                                   aria-current="page"
                                   aria-label={`${algoliaReducerConstants.Page} ${page}`}
                                   key={'ob-paging__page-number-' + page}
                                   onClick={() =>  {
                                    dispatch({ type: algoliaReducerConstants.pageNoClicked,payload:page,...state });  
                                    refine(page); 
                                  }}>
                        <span className="visuallyhidden">{state?.pageLabel}</span>
                        {page}
                    </button>
            }
        })}
    </div>
};

const getPageToIncrementAndDecrement = () => {

    let pagesToDecrement = state?.pageNoClicked;
    if (pagesToDecrement > 2) {
        pagesToDecrement = 2;
    }
    let pagesToIncrement = nbPages - state?.pageNoClicked;
    if (pagesToIncrement > 2) {
        pagesToIncrement = 2;
    }

    let remainingPages = 5 - pagesToDecrement - pagesToIncrement - 1;
    if (pagesToDecrement < pagesToIncrement) {
        pagesToIncrement = pagesToIncrement + remainingPages;
        if (state?.pageNoClicked + pagesToIncrement > nbPages) {
            pagesToIncrement = nbPages - state.pageNoClicked;
        }
    } else if (pagesToDecrement > pagesToIncrement) {
        pagesToDecrement = pagesToDecrement + remainingPages;
        if (state.pageNoClicked  - pagesToDecrement < 1) {
            pagesToDecrement = state.pageNoClicked - 1;
        }
    }
    
    return {pagesToIncrement, pagesToDecrement};
};

const getPageIndexes = (pagesToIncrement, pagesToDecrement) => {
  let pageIndexes = [];

  for (let i = state?.pageNoClicked - pagesToDecrement; i < state?.pageNoClicked; i++) {
      pageIndexes.push(i);
  }

  pageIndexes.push(state?.pageNoClicked);

  for (let i = state?.pageNoClicked + 1; i <= state?.pageNoClicked + pagesToIncrement; i++) {
      pageIndexes.push(i);
  }
  
  return pageIndexes;
};
return(
  nbPages >1 &&
  (<div className="ob-paging-wrapper" >
    <nav aria-label='pagination' className="ob-paging">
            {nbPages > 1 &&
                <button 
                onClick={() =>  {
                  dispatch({ type: algoliaReducerConstants.pageNoClicked,payload:state?.pageNoClicked-1,...state }); 
                  refine(state?.pageNoClicked-1);  
                }}
                        disabled={!state?.pageNoClicked> 1 || state?.pageNoClicked===1}
                        className={('ob-paging-button', {'ob-paging-button--disabled': !state?.pageNoClicke})}
                        data-action-detail={AnalyticsConstants.previousPage}>
                    <span className="ob-paging-icon icon-chevronleft"/>
                  
                </button>
            }
            
    {getPageNumbers()}
    {nbPages > 1 &&
                <button  onClick={() =>  {
                  dispatch({ type: algoliaReducerConstants.pageNoClicked,payload:state?.pageNoClicked+1,...state }); 
                  refine(state?.pageNoClicked+1);  
                }}
                        disabled={state?.pageNoClicked===nbPages}
                        className={('event_button_click ob-paging-button', {'ob-paging-button--disabled': !state?.pageNoClicked < nbPages})}
                        data-action-detail={AnalyticsConstants.nextPage}>
                    <span className="ob-paging-icon icon-chevronright"/>
                    <span className="visuallyhidden">{state?.pageNoClicked < nbPages}</span>
                </button>
            }
    
    </nav>
  </div>
))};

const ConnectedPagination = connectPagination(Pagination);

export default React.memo(ConnectedPagination)
