import Icon from '../Icon/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import {BuySeriesConstants} from '../../adapters/helpers/Constants';
import Button from '../Button/Button';
import {stripHtml} from '../../adapters/helpers/Utils';

export default function BuySeries({className, series, onClickCallback}) {
    const href = series?.fields?.ctaLink;
    const label = series?.fields?.ctaLabel;
    const dataActionDetail = series?.fields?.ctaDataActionDetail;
    const product = series?.fields?.featuredProduct;
    const buyNowDeactivated = product?.fields?.deactivateBuyNowButton || false;
    const comingSoonText = product?.fields?.comingSoonLabel?.fields?.text || '';
    const comingSoonLink = product?.fields?.comingSoonLink || '';
    const poweredByLabel = product?.fields?.poweredBy || '';

    if (!href || !label) {
        return null;
    }
    
    return (
        <div className={className}>
            {(series.fields.experienceLink && series.fields.experienceLinkText) ? (
                <h3 className="ob-series-selector__selected-buy-heading">
                    <a className="ob-series-selector__selected-buy-heading-link ob-series-selector__selected-buy-heading-link--experience event_button_click" data-action-detail={series.fields.experienceLink} href={series.fields.experienceLink}>
                        <span className="ob-series-selector__selected-buy-heading-text">
                            {series.fields.experienceLinkText.fields.text}
                        </span>

                        <Icon className="ob-series-selector__selected-buy-heading-icon" name={BuySeriesConstants.chevronRight} size={1.4} />
                    </a>
                </h3>
            ) : (product && series.fields.showExperienceLink) ? (
                <h3 className="ob-series-selector__selected-buy-heading">
                    <a className="ob-series-selector__selected-buy-heading-link event_button_click" data-action-detail={stripHtml(product?.fields?.shortTitle) || stripHtml(product.fields.title)} href={product.fields.slug.fields.slug}>
                        <span className="ob-series-selector__selected-buy-heading-text"
                            dangerouslySetInnerHTML={{ __html: product?.fields?.shortTitle || product.fields.title }}
                        />
                    </a>
                </h3>
            ) : (
                null
            )}

            {!buyNowDeactivated &&
                <Button
                    className={'ob-series-selector__selected-buy-btn event_button_click'}
                    onClick={event => onClickCallback(event)}
                    dataActionDetail={stripHtml(product?.fields?.title)}
                    sku={product?.fields?.productVariants[0]?.fields?.sku}
                >
                    {label?.fields?.text}
                </Button>
            }
            {buyNowDeactivated &&
                <Button
                    tag={'a'}
                    ariaLabel={stripHtml(`${label?.fields?.text} ${series?.fields?.title} ${poweredByLabel}`)}
                    disabled={comingSoonLink === ''}
                    href={comingSoonLink}
                    target={'_blank'}
                >
                    {comingSoonText}
                </Button>
            }
        </div>
    );
}

BuySeries.propTypes = {
    className: PropTypes.string,
    series: PropTypes.any,
    onClickCallback: PropTypes.func.isRequired,
}
