import React, { Component, Fragment } from 'react';
import SlideToggle from 'react-slide-toggle';
import Block from '../../adapters/helpers/Block';
import { scrollIntoView } from '../../adapters/helpers/Scroll';
import HierarchyUtils from '../../adapters/helpers/HierarchyUtils';
import { Label } from '../Label/Label';
import Image from '../Image/Image';
import Icon from '../Icon/Icon';
import PropTypes from 'prop-types';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import throttle from 'lodash/throttle';
import { MiniNavigationConstants, ContentConstants } from '../../adapters/helpers/Constants';
import { AnalyticsConstants } from '../../adapters/helpers/ConstantsGA';
import ObLink from '../ObLink/ObLink';

class MiniNavigation extends Component {

    _desktopBreakpoint = 992;
    constructor(props) {
        super(props);
        this.block = new Block(props);

        this.placeholderTitle = this.block.props.document.fields.placeholderTitle;
        this.renderHierarchy = this.renderHierarchy.bind(this);

        this.state = {
            isOpened: false,
        }
    }

    componentDidMount() {
        window.addEventListener(MiniNavigationConstants.resize, this.handleResizeThrottled);
    }

    componentWillUnmount() {
        window.removeEventListener(MiniNavigationConstants.resize, this.handleResizeThrottled);
    }

    handleResize() {
        let windowWidth = window.innerWidth;

        if (windowWidth >= this._desktopBreakpoint && this.state.isOpened) {
            this.setState({
                isOpened: false,
            });
        }
    }

    handleResizeThrottled = throttle(this.handleResize.bind(this), 100);

    renderHierarchy(onClickHandler) {
        const hierarchy = this.block.getFieldValue(MiniNavigationConstants.hierarchy);
        const className = this.block.getFieldValue(ContentConstants.classNames);
        const contentOptions = this.block.getFieldValue(ContentConstants.contentOptions);
        const entity = this.props.extraAttributes?.entity;
        let destinations = hierarchy?.fields?.destinations;
        const shownIcon = hierarchy?.fields?.type;

        if(!destinations && entity){
            const { relatedLinks } = entity;
            destinations = relatedLinks;
        }

        const scrollToView = (sectionId) => {
            const section = document.getElementById(`${sectionId}`);
            section && section.scrollIntoView();
        };

        const renderTileMenus = () => {
            return <div className={`content-options ${className}`}>
                {contentOptions.map(contentOption => {
                    const option = contentOption?.fields;
                    return (
                        <ObLink style={{ cursor: 'pointer' }} onClick={() => scrollToView(option?.ctaUrl)} key={option?.name} href={`#${option?.ctaUrl}`} className={Label({ label: option?.class })}>
                            {option?.icon && <Image className="post-purchase-hero-section__icon" image={option?.icon} />}
                            {option?.description && <p className="post-purchase-hero-section__description">{option?.description}</p>}
                            {option?.subIcon && <Image className="post-purchase-hero-section__sub-icon" image={option?.subIcon} />}
                        </ObLink>)
                })}
            </div>;
        };

        return (
            <Fragment>
                {destinations &&
                    <ul className='ob-mini-nav__list'>
                        {destinations.map((destination, index) => (
                            <li
                                className='ob-mini-nav__list-item'
                                key={index}
                                data-index={index}>
                                {HierarchyUtils.renderDestination(destination, null, null, onClickHandler)}
                                {shownIcon===MiniNavigationConstants.downArrow &&
                                <div className="chrevonDown-IconAlign" onClick={e => this.onClickHandlerBtnIcon(e,destination)}>
                                <Icon name={MiniNavigationConstants.chevronDown} />
                                </div>
                                }
                            </li>
                        ))
                        }
                    </ul>
                }
                 {contentOptions && renderTileMenus()}
            </Fragment>
        )
    }

    onClickHandler(event) {
        event.preventDefault();
        const currentTarget = event.currentTarget;
        if (currentTarget) {
            const anchorId = currentTarget.getAttribute(MiniNavigationConstants.href);
            const element = document.querySelector(anchorId);
            scrollIntoView(element, { behavior: MiniNavigationConstants.smooth });
        }
    }
    
    onClickHandlerBtnIcon(event,currentTarget) {
        event.preventDefault();
        if (currentTarget) {
          const  currentURL = currentTarget?.fields?.url;
          const type = currentTarget?.fields?.type;
            if(currentURL !== '_blank' && type!=='external') {
                const element = document.querySelector(currentURL);
                scrollIntoView(element, { behavior: MiniNavigationConstants.smooth });
            } else if(type === 'external') {        
                window.open(currentURL);
            }
        }
    }

    render() {
        const hierarchy = this.block.getFieldValue(MiniNavigationConstants.hierarchy);
        let isEmpty = !hierarchy?.fields?.destinations && !this.props.extraAttributes?.entity?.relatedLinks ? 'empty' : '';

        const className = this.block.getFieldValue(MiniNavigationConstants.classNames);
        const greyBackground = this.block.getFieldValue(MiniNavigationConstants.backgroundGrey) ? 'grey-background' : '';

        return (
            <div className={`ob-mini-nav ${className ? className : ''} ${greyBackground ? greyBackground : ''} ${isEmpty}`}>
                {(this.props.isLarge && isEmpty !== 'empty') ? (
                    <SlideToggle
                        collapsed
                        duration={500}
                        onExpanding={() => {
                            this.setState({ isOpened: true })
                        }}
                        onCollapsing={() => {
                            this.setState({ isOpened: false })
                        }}>
                        {({ onToggle, setCollapsibleElement }) => (
                            <Fragment>
                                <button className='ob-mini-nav__title event_menu_click'
                                        aria-expanded={this.state.isOpened}
                                        data-action-detail={AnalyticsConstants.dropdownMenu}
                                        onClick={onToggle}>
                                    <span>{this.placeholderTitle}</span>
                                    <span className={`${this.state.isOpened ? 'is-open ' : ''}ob-mini-nav__icon`}>
                                        <Icon name={MiniNavigationConstants.chevronDown} />
                                    </span>
                                </button>

                                <div ref={setCollapsibleElement}>
                                    {this.renderHierarchy(this.onClickHandler.bind(this))}
                                </div>
                            </Fragment>
                        )}
                    </SlideToggle>
                ) : (
                        <Fragment>
                            {this.renderHierarchy(this.onClickHandler.bind(this))}
                        </Fragment>
                    )}
            </div>
        )
    }
}

export default mediaQueryHOC(MiniNavigation);
// This export is for unit testing (do not remove) :
export const MiniNavigationTest = MiniNavigation;

MiniNavigation.propTypes = {
    extraAttributes: PropTypes.object,
    entity: PropTypes.object,
    isLarge: PropTypes.bool,
};
