import { useState } from 'react';
import { fetchOptinId, fetchSampleId, gcsRegister, gcsTriggerEmail } from './gcsService';
import { generateRegisterPayload, generateSendPayload } from '../../../helpers/Utils';
import { ClicConstant } from '../../../helpers/Constants';

const clicRegisterService = (initialValues, validate, languageCode, registerPopup) => {
    const [inputs, setInputs] = useState(initialValues);
    const [errors, setErrors] = useState(null);
    const [isError, setIsError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isResponseReceived, setResponseReceived] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const {successResponse, serverErrorResponse, emailErrorResponse, emptyFieldError, invalidEmail} = registerPopup?.fields || {}

    const resetState = () => {
        setIsLoading(false)
        setResponseReceived(false)
        setErrors(null)
        setInputs({ email: '', firstName: '', lastName: '', agree: 0 })
    }

    const handleSubmit = async (event) => {
        if (event) {
            event.preventDefault();
        }
        setResponseReceived(false)
        const validationErrors = validate(inputs, emptyFieldError, invalidEmail);
        const noErrors = Object.keys(validationErrors).length === 0;
        setIsError(!noErrors)
        setErrors(validationErrors);
        if (noErrors) {
            setIsLoading(true)
            try {
                const optinId = await fetchOptinId();
                const sampleId = await fetchSampleId(); 
                const registrationPyload = generateRegisterPayload(languageCode, inputs, optinId, sampleId)
                const registerResponse = await gcsRegister(registrationPyload)
                if (registerResponse.status === 200) {
                    setResponseMessage(successResponse);
                    const sendPayload = generateSendPayload(inputs);
                    const sendResponse = await gcsTriggerEmail(sendPayload);
                    if (sendResponse.status === 200) {
                        setResponseMessage(successResponse);
                    } else {
                        setResponseMessage(serverErrorResponse);
                    }
                } else if (registerResponse.status === 400) {
                    setResponseMessage(emailErrorResponse)
                } else {
                    setResponseMessage(serverErrorResponse)
                }
                setIsLoading(false)
                setResponseReceived(true)
            } catch (error) {
                console.error('clic register error=>', error)
                setIsLoading(false)
                setResponseMessage(serverErrorResponse)
                setResponseReceived(true)
            }
        } else {
            console.error('errors try again', validationErrors);
        }


    }
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({
            ...inputs, [event.target.name]: (event.target.name === ClicConstant.agree) ?
                (event.target.checked ? 1 : 0) : event.target.value
        }));
    }
    return {
        handleSubmit,
        handleInputChange,
        inputs,
        errors,
        isError,
        isResponseReceived,
        responseMessage,
        resetState,
        isLoading
    };
}
export default clicRegisterService;