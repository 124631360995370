import {AnalyticsConstants} from '../../adapters/helpers/ConstantsGA';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import VideoPlayerFactory from '../../adapters/helpers/VideoPlayerFactory';
import {VideoPlayerConstants} from '../../adapters/helpers/Constants';

class VideoPlayer extends Component {

    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.videoPlayer = null;
        this.video = props.video;
        this.state = {
            video: null
        }
    }

    getVideo() {
        return this.video;
    }

    getVideoRef() {
        return this.videoRef?.current;
    }

    getVideoCustomID(string) {
        let videoCustomId = this.video?.assetId;
        if(videoCustomId) {
            videoCustomId = videoCustomId.split('/');

            return string + '-' + videoCustomId[videoCustomId.length - 1];
        }
    }

    getVideoPlayerRef() {
        return this.videoPlayer;
    }

    componentDidMount() {
        if (this.videoIsDefined()) {
            const videoPlayerFactory = new VideoPlayerFactory(this.videoRef, this.video);
            this.createVideoPlayer(videoPlayerFactory);
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.state && this.state.video && this.video !== this.props.video) {
            const subTitles = this.props.video?.subTitles?.map((subtitle)=>({
                label: subtitle?.fields?.label,
                language: subtitle?.fields?.language,
                url: subtitle?.fields?.url
            }))
            const textTracks = {
                captions : {
                    label : this.props.video?.captions?.fields?.label,
                    default: this.props.video?.captions?.fields?.default,
                    language : this.props.video?.captions?.fields?.language,
                    url : this.props.video?.captions?.fields?.url
                },
                subtitles: subTitles && [...subTitles]
            }
            this.state.video.source(this.video?.asset?.fields?.file?.url, {
            textTracks: subTitles && this.props.video?.captions && textTracks });
            if (this.props.video.videoSettings) {
                const videoSettings = this.props.video.videoSettings.fields;
                const transformations = {
                    video_codec: videoSettings?.videoFormat || 'auto',
                    quality: videoSettings?.quality || 'auto',
                    width: videoSettings?.width || null,
                    height: videoSettings?.height || null,
                    crop: videoSettings?.crop || 'limit',
                };
                this.state.video.transformation(transformations);
            }
        }
    }

    createVideoPlayer(videoPlayerFactory) {
        const subTitles = this.props.video?.subTitles?.map((subtitle)=>({
            label: subtitle?.fields?.label,
            language: subtitle?.fields?.language,
            url: subtitle?.fields?.url
        }))
        let textTracks = {
        captions : {
            label : this.props.video?.captions?.fields?.label,
            default: this.props.video?.captions?.fields?.default,
            language : this.props.video?.captions?.fields?.language,
            url : this.props.video?.captions?.fields?.url
        },
        subtitles: subTitles && [...subTitles]
    }
        textTracks = subTitles && this.props.video?.captions && textTracks
        const source = textTracks ? { sourceTypes: ['webm', 'mp4', 'hls', 'ogg'], textTracks : textTracks } : { sourceTypes: ['webm', 'mp4', 'hls', 'ogg']}
        videoPlayerFactory.create()
            .then(({videoPlayer, transformation}) => {
                const video = videoPlayer.Cloudinary
                    .new({  secure: true })
                    .videoPlayer(this.videoRef.current,
                        {
                            muted: this.video.muted,
                            loop: this.video.loop,
                            fontFace: 'inherit',
                            fluid: true,
                            preload: this.video.preload ? true : false,
                            controls: this.video.controls,
                            autoplayMode: this.video.autoplay,
                            playsinline: this.video.playsInline
                        })
                    .transformation(transformation)
                    .source(this.video?.asset?.fields?.file?.url, source);
                this.setState({
                    video :  video
                });
                this.videoPlayer = video;
                this.videoPlayer.on('canplay', this.onVideoLoaded.bind(this));
                this.videoPlayer.on('play', this.onVideoPlay.bind(this));
                this.videoPlayer.on('pause', this.onVideoPause.bind(this));
                this.videoPlayer.on('mute', this.onVideoMuted.bind(this));
                this.videoPlayer.on('unmute', this.onVideoUnMuted.bind(this));
                this.videoPlayer.on('fullscreenchange', this.onVideoFullScreen.bind(this));
        })
            .catch(() => {
            });
    }

    componentWillUnmount() {
        if (this.videoPlayer) {
            this.videoPlayer.off('canplay', this.onVideoLoaded.bind(this));
            this.videoPlayer.off('play', this.onVideoPlay.bind(this));
            this.videoPlayer.off('pause', this.onVideoPause.bind(this));
            this.videoPlayer.off('mute', this.onVideoMuted.bind(this));
            this.videoPlayer.off('unmute', this.onVideoUnMuted.bind(this));
            this.videoPlayer.off('fullscreenchange', this.onVideoFullScreen.bind(this));
        }
    }

    onVideoLoaded() {
        this.videoPlayer.off('canplay', this.onVideoLoaded.bind(this));
        this.setAnalyticsPlayPauseTags();
        this.setAnalyticsMuteUnmuteTags();
        this.setAnalyticsFullScreenTags();
        this.setAnalyticsCaptionsTags();
        this.setVideoDOMContent();
    }

    onVideoPause() {
        if(this.props.onVideoPlaying) {
            this.props.onVideoPlaying(false);
        }
        this.setAnalyticsPlayPauseTags();
    }

    onVideoPlay() {
        if(this.props.onVideoPlaying) {
            this.props.onVideoPlaying(true);
        }
        this.setAnalyticsPlayPauseTags();
    }

    onVideoMuted() {
        this.setAnalyticsMuteUnmuteTags();
    }

    onVideoUnMuted() {
        this.setAnalyticsMuteUnmuteTags();
    }

    onVideoFullScreen() {
        this.setAnalyticsFullScreenTags();
    }

    setAnalyticsPlayPauseTags() {
        if(this.videoRef.current.parentNode) {
            let isVideoPaused = this.videoRef.current.parentNode.classList.contains('vjs-paused');
            let playPauseButton = this.videoRef.current.parentNode.querySelector('button.vjs-play-control');
            this.setElementAnalytics(playPauseButton, 'event-video-play', AnalyticsConstants.videoPlayText, AnalyticsConstants.videoPauseText, !isVideoPaused);
            let bigPlayButton = this.videoRef.current.parentNode.querySelector('button.vjs-big-play-button');
            this.setElementAnalytics(bigPlayButton, 'event-video-play', AnalyticsConstants.videoPlayText, AnalyticsConstants.videoPauseText, !isVideoPaused);
        }
    }

    setAnalyticsMuteUnmuteTags() {
        if(this.videoRef.current.parentNode) {
            let isVideoMuted = this.videoRef.current.getAttribute('muted');
            let muteUnmuteButton = this.videoRef.current.parentNode.querySelector('button.vjs-mute-control');
            this.setElementAnalytics(muteUnmuteButton, 'event-button-click', AnalyticsConstants.videoUnmuteText, AnalyticsConstants.videoMuteText, !isVideoMuted);
        }
    }

    setAnalyticsFullScreenTags() {
        if(this.videoRef.current.parentNode) {
            let isVideoFullScreen = this.videoRef.current.parentNode.classList.contains('vjs-fullscreen');
            let fullscreenButton = this.videoRef.current.parentNode.querySelector('button.vjs-fullscreen-control');
            this.setElementAnalytics(fullscreenButton, 'event-button-click', AnalyticsConstants.videoFullscreenOffText, AnalyticsConstants.videoFullscreenOnText, !isVideoFullScreen);
        }
    }

    setAnalyticsCaptionsTags() {
        if(this.videoRef.current.parentNode) {
            let isCaptionsVisible = this.videoRef.current.parentNode.querySelector('div.vjs-text-track-cue');
            let captionsButton = this.videoRef.current.parentNode.querySelector('button.vjs-subs-caps-button');
            this.setElementAnalytics(captionsButton, 'event-button-click', AnalyticsConstants.videoCloseCaptionsOffText, AnalyticsConstants.videoCloseCaptionsOnText, !isCaptionsVisible);
        }
    }

    setElementAnalytics(element, className, dataActionDetail, dataActionDetailAlternative = '', isAlternative = false) {
        if (element) {
            if (!element.classList.contains(className)) {
                element.classList.add(className);
            }

            if (!isAlternative) {
                element.setAttribute('data-action-detail', dataActionDetail);
            } else {
                element.setAttribute('data-action-detail', dataActionDetailAlternative);
            }
        }
    }

    setVideoDOMContent() {
        if (this.videoRef.current.parentNode) {
            let overlayItem = this.videoRef.current.parentNode.querySelector('div.vjs-recommendations-overlay-item-info');
            let cloudinaryButton = this.videoRef.current.parentNode.querySelector('a.vjs-cloudinary-button');
            if(cloudinaryButton){
                cloudinaryButton.innerHTML = "Cloudinary Logo";
            }
            if (overlayItem) {
                let h2Ele = overlayItem.getElementsByTagName('h2');
                let h3Ele = overlayItem.getElementsByTagName('h3');
                h2Ele && h2Ele[0] ? h2Ele[0].innerHTML = VideoPlayerConstants.videoRecommendations : '';
                h3Ele && h3Ele[0] ? h3Ele[0].innerHTML = VideoPlayerConstants.videoRecommendations : '';
            }
        }
    }

    videoIsDefined() {      
        return (this.video && this.video?.asset?.fields?.file?.url);
    }

    renderTranscript() {
        return <div className="video-player-transcript visuallyhidden">{this.video?.transcript}</div>
    }

    render() {
        const captionsTrack = this.props.video?.captions?.fields;
        const subtitlesVideo= this.props.video?.subTitles;
        const subTitleTrack = subtitlesVideo && subtitlesVideo[0] && subtitlesVideo[0].fields;
        const ariaLabel = this.props.video?.ariaLabel
        return (
            <div className="ob-video-player">
                <video
                    muted
                    playsInline
                    id={this.getVideoCustomID(VideoPlayerConstants.id)}
                    ref={this.videoRef}
                    aria-label={ariaLabel}   
                    crossOrigin="anonymous"                 
                >
                    <source src={'https:' + this.video?.asset?.fields?.file?.url} type='video/mp4' />
                    {captionsTrack && <track label={captionsTrack?.label} kind="captions" srcLang={captionsTrack?.language} src={captionsTrack?.url} default={captionsTrack?.default}></track>}
                    {subTitleTrack && <track label={subTitleTrack?.label} kind="subtitles" srcLang={subTitleTrack?.language} src={subTitleTrack?.url} default={subTitleTrack?.default}></track>}
                    </video>
                {this.renderTranscript()}
            </div>
        )
    }
}

VideoPlayer.propTypes = {
    video: PropTypes.object,
    onVideoPlaying: PropTypes.func,
};

export default VideoPlayer
