import { validateEmail } from '../../adapters/helpers/Utils';
const validate = (inputs, emptyFieldError, invalidEmail) => {
    //Email errors
    const errors = {};
    if (!inputs.email) {
        errors.email = emptyFieldError;
    } else if (validateEmail(inputs.email)) {
        errors.email = invalidEmail;
    }

    //first name Errors
    if (!inputs.firstName) {
        errors.firstName = emptyFieldError
    }
    //last name Errors
    if (!inputs.lastName) {
        errors.lastName = emptyFieldError
    }
    return errors;
}

export default validate;