import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import {
    CheckboxInput, FormBirthdate,
    FormInput,
    SubmitButton
} from '../../../components'
import ProfileStore from '../ProfileForm/profile.store';
import {registerValidations} from '../form.validators'
import {observer} from 'mobx-react'
import {inputs} from './inputs'
import FormSelect from '../../../components/FormSelect/FormSelect';
import Eyebrow from '../../../../Eyebrow/Eyebrow';
import {Markdown} from '../../../../MarkdownText/MarkdownText';
import useJanrainGlobalState from '../useJanrainGlobalState';
import {FormErrors} from '../index';
import {
    getJanrainCaptureProfileDataFromLocalStorage,
    removeSessionTimeoutFromLocalStorage,
    setIsCreatingAccountToLocalStorage, setSessionTimeoutFromLocalStorage
} from '../../../helpers/localstorage.helpers';
import {setMonths, setYears} from '../../../../../adapters/helpers/Utils';
import Modal from 'react-modal';
import Icon from '../../../../Icon/Icon';
import {getPostReplaceEntityObject, postReplaceGoals, sendEmailVerification, refreshEntity} from '../../../../../adapters/model/service/api/svc';
import {triggerRegisiterEvent, setProfileData } from '../../../helpers/localstorage.helpers';
import BodyText from '../../../../BodyText/BodyText';
import { FormGoalsCheckboxes } from '../../../components';
import { formatDate, isObjectEmpty } from '../../../../../adapters/helpers/Utils';

const registerStore = new ProfileStore(registerValidations, 13)

const RegisterForm = ({
  formHeading,
  formSubHeading,
  disclaimer,
  janrainLabels,
  janrainFormErrors,
  handleRegisterSuccess,
  alreadyHaveAnAccount,
  passwordRequirements,
  whyWeAskForDateOfBirth,
  whyWeAskForDateOfBirthLegal,
  formStep,
  countryOptionList,
  optId,
  optText,
  formLanguage,
  disclaimerBottom,
  emailDisclaimer,
  postalCodeValidationRegex,
  postalCodeSecondPattern,
  utmSource,
  ariaFormIsSubmitting,
  minimumAge,
  activateDoubleOptInEmailVerification,
  oralCareGoals
}) => {
    const [isFormProcessing, setFormProcessing] = useState(false);
    const [showError, setShowError] = useState(false);
    const [globalState, setGlobalState] = useJanrainGlobalState();
    const [isOptStatusChecked, setOptStatus] = useState(false);
    const [arrMonths, setArrMonths] = useState([]);
    const [arrYear, setArrYear] = useState([]);
    const [goalsJson, setGoalsJson] = useState({});
    const [reminder, setReminder] = useState(false);
    const [isFirstLegalModalOpen, setisFirstLegalModalOpen] = useState(false);
    const [isSecondLegalModalOpen, setIsSecondLegalModalOpen] = useState(false);
    const [isUnderAgeErrorModalOpen, setIsUnderAgeErrorModalOpen] = useState(false);
    const [optInId, setOptInId] = useState(optId);
    const blockRef = useRef(null);
    const legalModalRef = useRef(null);
    const underAgeErrorModalRef = useRef(null);
    const { form, errors, formValidated } = registerStore;

    const customStylesLegal = {
        overlay: {
            backgroundColor: 'none'
        },
        content : {
            position            : 'fixed',
            border              : 'none',
            top                 : '0',
            left                : '0',
            right               : '0',
            bottom              : '0',
            padding             : '0',
            marginRight         : '0',
            height              : '100%',
            background          : 'rgba(255, 255, 255, 0.5)',
            display             : 'flex',
            justifyContent      : 'center',
            alignItems          : 'center'
        }
    };

    const closeLegalModal = () => {
        setisFirstLegalModalOpen(false);
        setIsSecondLegalModalOpen(false);
    };

    const closeUnderAgeErrorModal = () => {
        setIsUnderAgeErrorModalOpen(false)
    };

    const setLegalModals = () => {
        if(blockRef.current) {
            let firstModalId = blockRef.current.querySelector('#openFirstLegalModal');
            if (firstModalId) {
                firstModalId.addEventListener('click', (e) => {
                    e.preventDefault();
                    setisFirstLegalModalOpen(true);
                })
            }

            let secondModalId = blockRef.current.querySelector('#openSecondLegalModal');
            if (secondModalId) {
                secondModalId.addEventListener('click', (e) => {
                    e.preventDefault();
                    setIsSecondLegalModalOpen(true);
                })
            }
        }
    };

    const janrainOpts = {
        globalOpt_optId: `${optInId}`,
        globalOpt_optStatus:  `${isOptStatusChecked}`,
        globalOpt_optText: optText,
        globalOpt_initialoptStatus: 'false',
        language: formLanguage
    };

    const utm = utmSource ? { traitTrafficSource: utmSource } : '';

    const handleRegistration = (e) => {
        e.preventDefault();
        setFormProcessing(true);
    };

    const regExpPattern = (patternFormat) => {
        const pattern = new RegExp(patternFormat);
        return pattern;
    }

    const validPostalCode = function(input) {
        const pattern = regExpPattern(postalCodeValidationRegex);
        if(pattern.test(input)) {
            return true;    
        } else {
            const secPattern = regExpPattern(postalCodeSecondPattern);
            return secPattern.test(input);
        }        
    };

    const setGoalsData = data => {
        setGoalsJson(data);
    };

    const setReminderData = data => {
        setReminder(data)
    };
    // Is duplicated in editProfile form, we try not to, but we need variable from the block context/scope
    const validAge = function(input) {
        let birthMonth = registerStore.form['birthdate[dateselect_month]'] - 1 || 0;
        const currentDate = new Date();
        const birthDate = new Date(input, birthMonth);
        // 31536000000 is the number of milisec in a year
        const age = (currentDate - birthDate) / 31536000000;
        return minimumAge <= age;
    };

    useEffect (() => {
        countryOptionList.forEach(option => {
            // value and id are switched here
            // form select has a mistake in the naming
            option.id = option.fields.countryValue;
            option.value = option.fields.countryDisplayName;
        });
        registerStore.clear();
        registerStore.errorTexts = janrainFormErrors;
        registerStore.fieldChange('birthdate[dateselect_day]', '01');
        setArrMonths(setMonths(janrainLabels.registerMonthSelectLabel));
        setArrYear(setYears(janrainLabels.registerYearSelectLabel));

        // dynamic postal code validation validation
        registerStore.validation.addressPostalCode.push(
            {
                error: 'postalCodeIsRequired',
                fn: validPostalCode
            });
        // dynamic minimumAge validation validation
        registerStore.validation['birthdate[dateselect_month]'].push({
            error: 'yearMustBeOldEnough',
            fn: validAge
        });

        if (document.querySelector('.js-modal-container')) {
            Modal.setAppElement('.js-modal-container');
            setLegalModals();
        }
    }, []);

    function onCaptureRegistrationSuccess() {
        handleRegisterSuccess && handleRegisterSuccess();
        removeSessionTimeoutFromLocalStorage();
        setSessionTimeoutFromLocalStorage();
    }

    function onCaptureRegistrationFailure() {
        setFormProcessing(false);
    }

    function postReplaceGoalsCallback() {
        refreshEntity(onCaptureRegistrationSuccess, onCaptureRegistrationFailure);
    }

    const updateTraitValues = () => {
        const janrainProfileData = JSON.parse(getJanrainCaptureProfileDataFromLocalStorage());
        let postObject = getPostReplaceEntityObject(janrainProfileData);
        delete postObject.traitProductType;
        delete postObject.primaryProduct;
        // reminder Date
        const reminderValue =  (reminder) ? 'Yes' : null;
        let reminderDate = formatDate(new Date());

        let traitBrushHeadReminderOptInFlagValue = {
            traitValue: reminderValue
        }
        postObject['traitBrushHeadReminderOptInFlag'] = traitBrushHeadReminderOptInFlagValue;
        if(!reminder) {
            reminderDate = null;
        }
        let traitBrushheadReminderDateValue = {
            traitDate: reminderDate
        }
        postObject['traitBrushheadReminderDate'] = traitBrushheadReminderDateValue;
        if (!isObjectEmpty(goalsJson)) {
            // BenefitPreferences
            let traitBenefitPreferencesValue = [];
            postObject['traitBenefitPreferences'] = [];
            goalsJson.forEach(goal => {
                let goalObject = {
                    traitValue: goal,
                    traitLastUpdated: new Date(),
                    traitLastUpdatedClientId: process.env.JANRAIN_CAPTURE_CIENT_ID
                }
                traitBenefitPreferencesValue.push(goalObject);
            });
            postObject['traitBenefitPreferences'] = traitBenefitPreferencesValue;
        } else {
            postObject['traitBenefitPreferences'] = [];
        }
        postReplaceGoals(postObject, postReplaceGoalsCallback, postReplaceGoalsCallback);
    };

    useEffect(() => {
        if (isFormProcessing) {
            const isValidAge = validAge(form['birthdate[dateselect_year]']);
            if(!form.addressCountry){
                globalState.formErrorResponse = {
                    form: 'registrationForm',
                    errors: [{ field: 'addressCountry', message: janrainFormErrors.countryIsAMandatoryField }],
                }
                setShowError(true);
                setFormProcessing(false);
                return;
            } else{
                setShowError(false);
            }
            
            /**
             * once after under age error, the notice popup shows always while submit,
             * because of the PSAT compliance check.
             */
            if (isValidAge && !sessionStorage.getItem('userBirthYear')) {
                window.janrain.events.onCaptureRegistrationFailed.addHandler(() => {
                    onCaptureRegistrationFailure();
                });
                window.janrain.events.onCaptureRegistrationSuccess.addHandler((data) => {
                    if (activateDoubleOptInEmailVerification && isOptStatusChecked) {
                        // even if the email fails we redirect to the profile because the janrain has been created.
                        sendEmailVerification(form.emailAddress, updateTraitValues, updateTraitValues);
                    } else {
                        updateTraitValues();
                        setProfileData();
                        triggerRegisiterEvent();
                    }
                });
                setIsCreatingAccountToLocalStorage('true');

                // adding correct locale to user
                form.locale = form.locale || process.env.JANRAIN_CAPTURE_LOCALE;

                // true for DE market
                if (activateDoubleOptInEmailVerification) {
                    janrainOpts.globalOpt_initialoptStatus = `${isOptStatusChecked}`;
                    janrainOpts.globalOpt_optStatus = 'false';
                }
                window.janrain.capture.ui.postCaptureForm(inputs.formName, {...form, ...janrainOpts, ...utm})
            } else {
                if (!sessionStorage.getItem('userBirthYear')) {
                    sessionStorage.setItem('userBirthYear', form['birthdate[dateselect_year]'] + '-' + form['birthdate[dateselect_month]']);
                }
                setIsUnderAgeErrorModalOpen(true);
                setFormProcessing(false);
            }
        }
    }, [isFormProcessing]);

    const updateAddressCountry = (selectedCountry) => {
        //to remove error message on selection of country
        setShowError(!selectedCountry);
        countryOptionList.forEach(option => {
            if (option.fields.countryValue === selectedCountry) {
                // add validation for dynamic attribute name added. Avoid validation to fail and throw error.
                // add value and dynamic attribute name for source Id and marketing Program Id
                if(option.fields.sourceIdAttributeName && option.fields.sourceId) {
                    registerStore.validation[option.fields.sourceIdAttributeName] = [];
                    registerStore.fieldChange(option.fields.sourceIdAttributeName, option.fields.sourceId);
                }
                if(option.fields.marketingProgramIdAttributeName && option.fields.marketingProgramId) {
                    registerStore.validation[option.fields.marketingProgramIdAttributeName] = [];
                    registerStore.fieldChange(option.fields.marketingProgramIdAttributeName, option.fields.marketingProgramId);
                }

                //Janrain set Locale for multi country locale
                form.locale = option?.fields?.countryLocale || process.env.JANRAIN_CAPTURE_LOCALE

                // set country optIn according to country selection
                setOptInId(option?.fields?.optInId || '');
            }
        });
    };

    return (
        <div className='ob-register js-modal-container' ref={blockRef}>
            <div className='ob-form__layout ob-register__wrapper'>

                <h1 className='ob-form__title' dangerouslySetInnerHTML={{__html: formHeading}}></h1>
                <p className='ob-form__subtitle' dangerouslySetInnerHTML={{__html: formSubHeading}}></p>
                <div className='ob-form__subtitle'>
                    <Markdown source={alreadyHaveAnAccount} />
                </div>
                {(globalState.isInvalidForm || showError) && <FormErrors {...globalState.formErrorResponse} predefinedErrors={janrainFormErrors}/>}
                <form className='ob-form__form'
                    name={inputs.formName}
                    onSubmit={handleRegistration}>
                    <Eyebrow className='ob-register__step-title'>{formStep}</Eyebrow>
                    <p className='ob-register__all-fields-required'>{janrainLabels.registerAllFieldsRequiredLabel}</p>
                    <div className='ob-form__wrapper ob-register__row'>
                        <FormInput
                            label={janrainLabels.registerFirstNameFieldLabel}
                            name={inputs.field.firstName}
                            inputType={inputs.type.text}
                            value={form.firstName || ''}
                            onChange={(e) => {registerStore.fieldChange(inputs.field.firstName, e.target.value);}}
                            validationErrorText={errors.firstName}
                        />
                        <FormInput
                            label={janrainLabels.registerLastNameFieldLabel}
                            name={inputs.field.lastName}
                            inputType={inputs.type.text}
                            value={form.lastName || ''}
                            onChange={(e) => registerStore.fieldChange(inputs.field.lastName, e.target.value)}
                            validationErrorText={errors.lastName}
                        />
                    </div>
                    <div className='ob-form__wrapper ob-register__row'>
                        <FormInput
                            label={janrainLabels.registerEmailAddressFieldLabel}
                            name={inputs.field.emailAddress}
                            inputType={inputs.type.email}
                            value={form.emailAddress || ''}
                            onChange={(e) => registerStore.fieldChange(inputs.field.emailAddress, e.target.value)}
                            validationErrorText={errors.emailAddress}
                        />
                        <FormInput
                            label={janrainLabels.registerReEnterEmailAddressFieldLabel}
                            name={inputs.field.emailAddressConfirm}
                            inputType={inputs.type.email}
                            value={form.emailAddressConfirm || ''}
                            onChange={(e) => registerStore.fieldChange(inputs.field.emailAddressConfirm, e.target.value)}
                            validationErrorText={errors.emailAddressConfirm}
                        />
                    </div>
                    <div className='ob-form__wrapper ob-register__row ob-register__row-password'>
                        <FormInput
                            label={janrainLabels.registerPasswordFieldLabel}
                            name={inputs.field.newPassword}
                            inputType={inputs.type.password}
                            value={form.newPassword || ''}
                            onChange={(e) => registerStore.fieldChange(inputs.field.newPassword, e.target.value)}
                            validationErrorText={errors.newPassword}
                            ariaDescribedBy='inputDescription'
                        />
                        <FormInput
                            label={janrainLabels.registerReEnterPasswordFieldLabel}
                            inputType={inputs.type.password}
                            name={inputs.field.newPasswordConfirm}
                            value={form.newPasswordConfirm || ''}
                            onChange={(e) => registerStore.fieldChange(inputs.field.newPasswordConfirm, e.target.value)}
                            validationErrorText={errors.newPasswordConfirm}
                            ariaDescribedBy='inputDescription'
                        />
                    </div>
                    {!errors.newPassword &&
                        <Eyebrow className='ob-register__pass-requirements ob-form__requirements' id='inputDescription'>
                            {passwordRequirements}
                        </Eyebrow>
                    }
                    <div className='ob-form__wrapper ob-register__row ob-register__row-other'>
                        <div className='ob-form__wrapper ob-register__birthdate-wrapper'>
                            <FormBirthdate
                                dateOfBirthLabel={janrainLabels.registerDateOfBirthFieldLabel}
                                whyWeAskForDateOfBirth={whyWeAskForDateOfBirth}
                                whyWeAskForDateOfBirthLegal={whyWeAskForDateOfBirthLegal}
                                arrMonths={arrMonths}
                                nameMonth={inputs.field.birthdate.dateselect_month}
                                monthLabel={janrainLabels.registerMonthSelectAriaLabel || janrainLabels.registerMonthSelectLabel}
                                valueMonth={form['birthdate[dateselect_month]'] || janrainLabels.registerMonthSelectLabel}
                                onChangeMonth={(e) => registerStore.fieldChange(inputs.field.birthdate.dateselect_month, parseInt(e.target.value, 10))}
                                errorMonth={errors['birthdate[dateselect_month]']}
                                arrYear={arrYear}
                                nameYear={inputs.field.birthdate.dateselect_year}
                                yearLabel={janrainLabels.registerYearSelectAriaLabel || janrainLabels.registerYearSelectLabel}
                                valueYear={form['birthdate[dateselect_year]'] || janrainLabels.registerYearSelectLabel}
                                onChangeYear={(e) => registerStore.fieldChange(inputs.field.birthdate.dateselect_year, e.target.value)}
                                errorYear={errors['birthdate[dateselect_year]']}
                            />
                            <FormInput
                                label={janrainLabels.registerZipCodeFieldLabel}
                                name={inputs.field.addressPostalCode}
                                inputType={inputs.type.text}
                                value={form.addressPostalCode || ''}
                                onChange={(e) => registerStore.fieldChange(inputs.field.addressPostalCode, e.target.value)}
                                validationErrorText={errors.addressPostalCode}
                            />
                        </div>
                        <FormSelect
                            selectLabel={janrainLabels.registerCountryFieldLabel}
                            name={inputs.field.addressCountry}
                            value={form.addressCountry || ''}
                            onChange={(e) => {
                                e.preventDefault();
                                registerStore.fieldChange(inputs.field.addressCountry, e.target.value);
                                updateAddressCountry(e.target.value);
                            }}
                            dataArray={countryOptionList}
                            validationErrorText={errors.addressCountry}
                        />
                    </div>
                    <div className='ob-register-product'>
                        <div className='ob-register-product__wrapper'>
                            <div>
                                <BodyText className='ob-register-product__goals-text'>{janrainLabels.oralCareGoals}</BodyText>
                                <Eyebrow>{janrainLabels.selectGoals}</Eyebrow>
                                <FormGoalsCheckboxes
                                    className='register-product-createprofile'
                                    janrainLabels={janrainLabels}
                                    oralCareGoals={oralCareGoals}
                                    setGoalsData={setGoalsData}
                                    setReminderData={setReminderData}/>
                            </div>
                        </div>
                    </div>

                    {emailDisclaimer &&
                        <>
                            {disclaimer &&
                                <div className='ob-register__info-text disclaimer-text-why' >
                                    <p dangerouslySetInnerHTML={{ __html: disclaimer }}></p>
                                </div>
                            }
                            <CheckboxInput
                                className={'checkbox-email-disclaimer'}
                                label={`<p>${emailDisclaimer}</p>`}
                                name={inputs.field.globalOpt_optStatus}
                                value={form.globalOpt_optStatus || false}
                                checked={isOptStatusChecked}
                                onChange={() => setOptStatus(!isOptStatusChecked)}
                            />

                            {disclaimerBottom &&
                                <div className='ob-register__info-text ob-register__info-text-bottom'>
                                    <p dangerouslySetInnerHTML={{ __html: disclaimerBottom }}></p>
                                </div>
                            }
                        </>
                    }

                    {disclaimerBottom && !disclaimer &&
                        <CheckboxInput
                            label={`<p>${disclaimerBottom}</p>`}
                            name={inputs.field.globalOpt_optStatus}
                            value={form.globalOpt_optStatus || false}
                            checked={isOptStatusChecked}
                            onChange={() => setOptStatus(!isOptStatusChecked)}
                        />
                    }

                    <SubmitButton
                        buttonText={janrainLabels.registerButtonText}
                        disabled={!formValidated || isFormProcessing}
                        isSubmitting={isFormProcessing}
                        ariaFormIsSubmitting={ariaFormIsSubmitting}
                    />
                </form>
            </div>
            {(janrainLabels.modalLabelFirst || janrainLabels.modalLabelSecond) &&
            <Modal
                isOpen={isFirstLegalModalOpen || isSecondLegalModalOpen}
                style={customStylesLegal}
                onRequestClose={closeLegalModal}
                closeTimeoutMS={250}
                overlayClassName={'event_button_click'}
                ref={legalModalRef}
            >
                <div className='ob-register__modal-container'>

                    { isFirstLegalModalOpen && janrainLabels.modalLabelFirst &&
                        <p dangerouslySetInnerHTML={{__html: janrainLabels.modalLabelFirst}}></p>
                    }

                    { isSecondLegalModalOpen && janrainLabels.modalLabelSecond &&
                        <p dangerouslySetInnerHTML={{__html: janrainLabels.modalLabelSecond}}></p>
                    }

                    <button className='event_close_window ob-modal-close'
                            onClick={closeLegalModal}
                            aria-label={janrainLabels.closeModalLabel}
                            type='button'
                    >
                        <Icon name='close' size='2.4' />
                    </button>

                </div>
            </Modal>
            }
            {janrainLabels.underAgeErrorLabel &&
                <Modal
                    isOpen={isUnderAgeErrorModalOpen}
                    style={customStylesLegal}
                    onRequestClose={closeUnderAgeErrorModal}
                    closeTimeoutMS={250}
                    overlayClassName={'event_button_click'}
                    ref={underAgeErrorModalRef}
                >
                    <div className='ob-register__modal-container'>
                        <p dangerouslySetInnerHTML={{__html: janrainLabels.underAgeErrorLabel}}></p>

                        <button className='event_close_window ob-modal-close'
                                onClick={closeUnderAgeErrorModal}
                                aria-label={janrainLabels.closeModalLabel}
                                type='button'
                        >
                            <Icon name='close' size='2.4' />
                        </button>

                    </div>
                </Modal>
            }
        </div>
    )
};

RegisterForm.propTypes = {
    formHeading: PropTypes.string.isRequired,
    disclaimer: PropTypes.string,
    ariaFormIsSubmitting: PropTypes.string,
    disclaimerBottom: PropTypes.string,
    janrainLabels: PropTypes.shape({
        registerAllFieldsRequiredLabel: PropTypes.string.isRequired,
        registerFirstNameFieldLabel: PropTypes.string.isRequired,
        registerLastNameFieldLabel: PropTypes.string.isRequired,
        registerEmailAddressFieldLabel: PropTypes.string.isRequired,
        registerReEnterEmailAddressFieldLabel: PropTypes.string.isRequired,
        registerPasswordFieldLabel: PropTypes.string.isRequired,
        registerReEnterPasswordFieldLabel: PropTypes.string.isRequired,
        registerDateOfBirthFieldLabel: PropTypes.string.isRequired,
        registerZipCodeFieldLabel: PropTypes.string.isRequired,
        registerCountryFieldLabel: PropTypes.string.isRequired,
        registerMonthSelectLabel: PropTypes.string.isRequired,
        registerYearSelectLabel: PropTypes.string.isRequired,
        registerButtonText: PropTypes.string.isRequired
    }).isRequired,
    janrainFormErrors: PropTypes.object.isRequired,
    utmSource: PropTypes.string
};

export default observer(RegisterForm);
// This export is for unit testing (do not remove) :
export const RegisterFormTest = RegisterForm;
