import PropTypes from 'prop-types';
import React, {useEffect, useLayoutEffect, useMemo} from 'react';
import ImageFactory from '../../adapters/cloudinary/ImageFactory';
import { findIndexByAnchorId } from '../../adapters/helpers/Anchor';
import Backdrop from '../Backdrop/Backdrop';
import MarkdownText from '../MarkdownText/MarkdownText';
import RichText from '../RichText/RichText';
import { SeriesSelectorConstants } from '../../adapters/helpers/Constants';
import SeriesTabs from './SeriesTabs';
import BuySeries from './BuySeries';
import SeriesComparison from './SeriesComparison';
import Block from '../../adapters/helpers/Block';
import Disclaimer from '../Disclaimer/Disclaimer';
import { stripHtml, smoothVerticalScroll } from '../../adapters/helpers/Utils';
export default function Series(props) {
    const {document: doc, extraAttributes} = props;
    const { entity: collection, query, slug: basePath} = extraAttributes;
    const selectedSeriesId = query?.series
    const seriesArray = collection.series;
    const selectedSeriesIndex = useMemo(
        () => findIndexByAnchorId(seriesArray, selectedSeriesId) || 0,
        [selectedSeriesId, seriesArray],
    );
    const selectedSeries = seriesArray?.[selectedSeriesIndex];
    const selectedSeriesProducts = selectedSeries?.fields?.products;
    useEffect(() => {
        if (typeof (window.dataLayer) !== 'undefined') {
            window.dataLayer.push({
                'event': 'VirtualPageview',
                'title': `${stripHtml(selectedSeries?.fields?.shortTitle)}`,
                'url': `${selectedSeries?.fields?.virtualPageViewUrl}`
            });
        }
    }, [selectedSeriesIndex]);

    const selectedSeriesRelatedBlocks = selectedSeries?.fields?.relatedBlocks|| null;

    return (
        <div className='ob-series'>
            <SeriesSelector
                document={doc}
                collection={collection}
                seriesArray={seriesArray}
                selectedSeries={selectedSeries}
                selectedSeriesIndex={selectedSeriesIndex}
                onClickCallback={props.onClickCallback}
                basePath={basePath}
            />

            {!!(selectedSeriesProducts && selectedSeriesProducts.length > 1) && (
                <SeriesComparison
                    document={doc}
                    products={selectedSeriesProducts}
                    selectedSeries={selectedSeries}
                    onClickCallback={props.onClickCallback}
                />
            )}

            {selectedSeriesRelatedBlocks &&
                selectedSeriesRelatedBlocks.map((block, index) => {
                    return new Block(block).renderBlockFromDocument({}, () => {}, {}, index);
                })
            }
        </div>
    );
}

Series.propTypes = {
    document: PropTypes.object,
    extraAttributes: PropTypes.object,
    selectedSeries: PropTypes.object,
    onClickCallback: PropTypes.func.isRequired,
}

function SeriesSelector(props) {
    const {document: doc, collection, onClickCallback} = props;
    const {seriesArray, selectedSeries, selectedSeriesIndex, basePath} = props;

    const blockSubtitleLabel = doc.fields.blockSubtitleLabel;
    const optionsLabel = doc.fields.optionsLabel;
    const disclaimer = collection?.disclaimer;

    const featuredProduct = selectedSeries?.fields?.featuredProduct;

    const selectedSeriesBackground = selectedSeries?.fields?.backgroundAsset;
    const mobileBackgroundAsset = selectedSeries?.fields?.mobileBackgroundAsset;
    const selectedSeriesOptions = selectedSeries?.fields?.options;

    const selectedSeriesImage = selectedSeries?.fields?.mainAsset;
    const selectedSeriesMobileImage = selectedSeries?.fields?.mobileAsset || featuredProduct?.fields?.mobileAsset;

    const shortTitle = collection.shortTitle;
    const seriesHeading = selectedSeries?.fields?.seriesHeading ? selectedSeries?.fields?.seriesHeading : collection.shortTitle;

    const style = {
        backgroundColor: selectedSeries?.fields?.backgroundColor,
    };

    const contentSelectedSeriesImage = selectedSeries?.fields?.mainAsset?.fields?.asset?.fields?.file?.url;
    const contentSelectedSeriesMobileImage = selectedSeries?.fields?.mobileAsset?.fields?.asset?.fields?.file?.url || featuredProduct?.fields?.mobileAsset?.fields?.asset?.fields?.file?.url

    const scrollToFeatures = event => {
        const currentElemHref = event.currentTarget.getAttribute('href');
        if (currentElemHref.charAt(0) === '#') {
            event.preventDefault();
            const targetElem = document.querySelector(currentElemHref);
            if (targetElem) {
                smoothVerticalScroll(targetElem, 'top');
            }
        }
    };

    useLayoutEffect(() => {
        if (document) {
            const exploreMoreAnchorLink = document.querySelectorAll('.series-explore-all-features a');
            if (exploreMoreAnchorLink) {
                exploreMoreAnchorLink.forEach(anchorLink => {
                    anchorLink.addEventListener('click', scrollToFeatures);
                    return () => anchorLink.removeEventListener('click', scrollToFeatures);
                })
            }
        }
    });


    // Master Product id containing the reviews of all the product in his family
    const reviewAndRatingMasterProductId = SeriesSelectorConstants.master + featuredProduct?.fields?.reviewAndRatingFamilyId;

    return (
        <Backdrop className={`ob-series-selector ${selectedSeries?.fields?.anchorId}-series ${doc?.fields?.classNames ? doc?.fields?.classNames : ''}`} style={style}>
            <Backdrop.Image image={selectedSeriesBackground} mobileImage={mobileBackgroundAsset} />

            <Backdrop.Content className='ob-series-selector__content'>
                <div className='ob-series-selector__top'>
                    <h1 className='ob-series-selector__heading' dangerouslySetInnerHTML={{__html: collection.shortTitle}}></h1>
                    {disclaimer &&
                        <Disclaimer document={disclaimer} />
                    }
                </div>

                <div className='ob-series-selector__series'>
                    {!!blockSubtitleLabel && (
                        <h2 className='ob-series-selector__subtitle'>
                            {blockSubtitleLabel.fields.text}
                        </h2>
                    )}

                    <SeriesTabs
                        items={seriesArray}
                        selectedItem={selectedSeries}
                        selectedIndex={selectedSeriesIndex}
                        basePath={basePath}
                    />
                </div>

                {!!selectedSeries && (
                    <div className='ob-series-selector__selected'>
                        <div className='ob-series-selector__selected-main'>
                            <div className='ob-series-selector__selected-image'>
                            {!!contentSelectedSeriesImage && (
                                    <div className='ob-series-selector__selected-image-wrap'>
                                        <picture className='ob-series-selector__selected-image-pic'>
                                            <source media={SeriesSelectorConstants.media} srcSet={ImageFactory.imageContentfullDpr(selectedSeriesImage, SeriesSelectorConstants.dpr)} />

                                            {!!contentSelectedSeriesMobileImage && (
                                                <source srcSet={ImageFactory.imageContentfullDpr(selectedSeriesMobileImage, SeriesSelectorConstants.dpr)} />
                                            )}

                                            <img className='ob-series-selector__selected-image-img'
                                                alt={selectedSeriesImage.fields.alternateText || SeriesSelectorConstants.empty}
                                                src={ImageFactory.imageContentfullSrc(contentSelectedSeriesImage, ContentSeriesSelectorConstants.dpr)}
                                                srcSet={ImageFactory.imageContentfullDpr(contentSelectedSeriesImage, ContentSeriesSelectorConstants.dpr)}
                                                style={{ maxHeight: selectedSeries.fields.assetHeight ? `${selectedSeries.fields.assetHeight}px` : '711px' }}
                                            />
                                        </picture>
                                    </div>
                                )}
                            </div>

                            <div className='ob-series-selector__selected-gap' />

                            <div className='ob-series-selector__selected-info'>

                                <h2 className='ob-series-selector__selected-name' dangerouslySetInnerHTML={{__html: selectedSeries.fields.title}}>
                                </h2>

                                {featuredProduct?.fields?.reviewAndRatingFamilyId && (
                                    <div data-bv-show={SeriesSelectorConstants.ratingSummary} data-bv-product-id={reviewAndRatingMasterProductId}></div>
                                )}

                                <MarkdownText className='ob-series-selector__selected-description'
                                    value={selectedSeries.fields.description}
                                />

                                {(selectedSeries.fields.highlights && selectedSeries.fields.highlights.length > 0) && (
                                    <ul className='ob-series-selector__selected-highlights'>

                                        {selectedSeries.fields.highlights.map((serieHightlight, i) => {

                                            if (serieHightlight?.fields?.highlight && serieHightlight?.fields?.iconName) {
                                                return (
                                                    <li className='ob-series-selector__selected-highlights-item' key={i}>
                                                        <span className={`ob-series-selector__selected-highlights-icon ${serieHightlight.fields.iconName}`} />

                                                        <span className='ob-series-selector__selected-highlights-text'>
                                                            <RichText document={serieHightlight.fields.highlight} />
                                                        </span>
                                                    </li>
                                                )
                                            } else if (serieHightlight?.fields?.highlight) {
                                                return (
                                                    <li className='ob-series-selector__selected-highlights-item' key={i}>
                                                        <span className='ob-series-selector__selected-highlights-bullet'>•</span>
                                                        <span className='ob-series-selector__selected-highlights-text'>
                                                            <RichText document={serieHightlight.fields.highlight} />
                                                        </span>
                                                    </li>
                                                )
                                            }

                                        })}
                                    </ul>
                                )}

                                {!!(selectedSeriesOptions) && (
                                    <div className='ob-series-selector__selected-options'>
                                        {!!optionsLabel && (
                                            <h3 className='ob-series-selector__selected-subheading ob-series-selector__selected-subheading--options'>
                                                {optionsLabel.fields.text}
                                            </h3>
                                        )}

                                        <MarkdownText className='ob-series-selector__selected-options-text'
                                            value={selectedSeriesOptions}
                                        />
                                    </div>
                                )}

                                <BuySeries onClickCallback={onClickCallback} className='ob-series-selector__selected-info-buy'
                                    series={selectedSeries}
                                />
                            </div>
                        </div>

                        <BuySeries onClickCallback={onClickCallback} className='ob-series-selector__selected-buy'
                            series={selectedSeries}
                        />
                    </div>
                )}
            </Backdrop.Content>
        </Backdrop>
    );
}

SeriesSelector.propTypes = {
    document: PropTypes.object,
    collection: PropTypes.any,
    seriesArray: PropTypes.any,
    selectedSeries: PropTypes.object,
    selectedSeriesIndex: PropTypes.any,
    onClickCallback: PropTypes.func.isRequired,
    basePath: PropTypes.any,
}
