import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Constants, ContentConstants, PostPurchaseConstants, UserQuoteConstants } from '../../adapters/helpers/Constants';
import { focusElement, addDropDownListerner, addBVScript } from '../../adapters/helpers/Utils';
import Image from '../Image/Image';
import Modal from 'react-modal';
import Heading from '../Heading/Heading';
import Button from '../Button/Button';
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks'

const PostPurchaseWriteReviewSection = (props) => {

    const textImageIcon = props?.document?.fields?.textImageIcon;
    const className = props?.document?.fields?.className;
    const title = props?.document?.fields?.title;
    const bodyText = props?.document?.fields?.bodyText;
    const writeReviewCtaText = props?.document?.fields?.writeReviewCta;
    const writeReviewCtaDataActionDetail = props?.document?.fields?.writeReviewCtaDataActionDetail;
    const staticReviewContent = props?.document?.fields?.staticReviewContent;
    const writeReviewSectionBackgroundDesktop = props?.document?.fields?.writeAReviewSectionBg;
    const writeReviewSectionBackgroundMobile = props?.document?.fields?.writeReviewSectionBackgroundMobile;
    const bvWidgetIcon = props?.document?.fields?.bvWidgetIcon;
    const bvWidgetTitle = props?.document?.fields?.bvWidgetTitle;
    const bvWidgetBody = props?.document?.fields?.bvWidgetBody;
    const seriesItems = props?.document?.fields?.seriesItems;
    const checkmarkIcon = props?.document?.fields?.checkmarkIcon;
    const bvWidgetDesktopBackgroundImage = props?.document?.fields?.desktopBackgroundImage;
    const bvWidgetMobileBackgroundImage = props?.document?.fields?.mobileBackgroundImage;
    const anchorId = props?.document?.fields?.anchorId;
    const closeModalLabel = props?.document?.fields?.closeModalLabel;
    const initialRatingandReviewId = seriesItems?.[0]?.fields?.productLinks?.[0]?.fields?.id;
    
    const [windowWidth, setWindowWidth] = useState(ContentConstants.defaultWidth);
    const [isModalOpen, setModalOpen] = useState(false);
    const [reviewAndRatingId, setReviewAndRatingId] = useState(initialRatingandReviewId);
    const [isWarCtaDisable, setWarCtaDisable] = useState(true);
    
    const isMobileView = windowWidth <= Constants.desktopViewStart;

    const customStylesBVWidget = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            position: 'absolute',
            background: 'linear-gradient(to right bottom, #000421, #000421)',
            borderRadius: '0',
            fontSize: '1.1rem',
            lineHeight: '2.1rem',
            padding: '0',
            transform: isMobileView ? 'translate(-50%, -47%)' : 'translate(-50%, -50%)',
            width: isMobileView ? '90%' : '100%',
            minWidth: isMobileView ? '90%' : '50%',
            maxWidth: isMobileView ? '100%' : '900px',
            height: isMobileView ? '80vh' : 'auto',
            maxHeight: isMobileView ? '80vh' : '90vh',
            overflowX: 'hidden',
            border: 'none'
        }
    };
    useEffect(() => {
        addDropDownListerner(PostPurchaseConstants?.dropBtnClass, PostPurchaseConstants?.dropdownContant, PostPurchaseConstants?.dropdownClass);
    }, []);

    function handleResize() {
        setWindowWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return (() => {
            window.removeEventListener('resize', handleResize)
        })
    }, []);

    useEffect(() => {
        addBVScript();
    }, []);

    useLayoutEffect(() => {
        setWarCtaDisable(false);
    }, []);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };
   
    const addWarPopupCloseCtaEventListener = () => {
        setTimeout(()=> {
            const closeElement = document.getElementsByClassName('bv-mbox-close');
            closeElement && closeElement[0] && closeElement[0].addEventListener('click', ()=> {
                document.querySelector('body').style.overflow = 'visible';
            });
        }, 2000);
    };

    const openWarPopup = (reviewId) => {
        setReviewAndRatingId(reviewId);
        setTimeout(() => {
            const warCta = document.getElementsByClassName(PostPurchaseConstants.WAR_POP_ID).length > 0 && document.getElementsByClassName(PostPurchaseConstants.WAR_POP_ID)[0]
            if (warCta) {
                document.querySelector('body').style.overflow = 'hidden';
                warCta.click();
                addWarPopupCloseCtaEventListener();
            }
        }, 500);
    }

    return (
        <section className={`post-purchase-write-review-section ${className ? className : ''}`} id={anchorId}>
            <Image image={isMobileView ? writeReviewSectionBackgroundMobile : writeReviewSectionBackgroundDesktop}>
                <div className='post-purchase-write-review-section__textContent'>
                    <div className='post-purchase-write-review-section__textContent-inner'>
                        {textImageIcon &&
                            <div className='post-purchase-write-review-section__text-image'>
                                <Image image={textImageIcon} />
                            </div>
                        }
                        <Heading
                            className={'post-purchase-write-review-section__title'}
                            whiteText={true}
                            tag={'h2'}>
                            {title}
                        </Heading>
                        <p className='post-purchase-write-review-section__body'>{bodyText}</p>
                        <Button
                            whiteTheme={true}
                            disabled={isWarCtaDisable}
                            className={'post-purchase-write-review-section__cta-write-review event_product_review'}
                            dataActionDetail={writeReviewCtaDataActionDetail}
                            onClick={() => openModal()}>
                            {writeReviewCtaText}
                        </Button>
                        <hr className='post-purchase-write-review-section__divider' />
                        <div className={'post-purchase-write-review-section__review-content'} dangerouslySetInnerHTML={{__html: staticReviewContent}} />
                    </div>
                </div>
            </Image>
            <Modal
                isOpen={isModalOpen}
                contentLabel={anchorId}
                aria={{ modal: true }}
                portalClassName="post-purchase-bv-widget"
                onRequestClose={closeModal} closeTimeoutMS={250}
                style={customStylesBVWidget}>
                <div className='post-purchase-bv-widget__wrapper'>
                    <button aria-label={closeModalLabel} onClick={closeModal} className={'post-purchase-bv-widget__close-modal'} type='button'>
                        <Icon name={PostPurchaseConstants.close} size='1.4' color={Constants.white} />
                    </button>
                    <div className={'post-purchase-bv-widget__inner-wrapper'} id={anchorId} >
                        <Image classname='post-purchase-bv-widget__desktop-bg-img' image={isMobileView ? bvWidgetMobileBackgroundImage : bvWidgetDesktopBackgroundImage} customStyles={{ backgroundSize: 'cover !important', backgroundRepeat: 'no-repeat', padding: '0', minHeight: '590px' }}>
                            <div className={'post-purchase-bv-widget__inner-text-wrapper'}>
                                <Image image={bvWidgetIcon} className='post-purchase-bv-widget__bv-widget-icon' />
                                <Heading className={'post-purchase-bv-widget__title'}>{bvWidgetTitle}</Heading>
                                <hr className='post-purchase-bv-widget__divider' />
                                <p className={'post-purchase-bv-widget__label-second-step'}>{bvWidgetBody}</p>

                                <div className='post-purchase-bv-widget__series-container'>
                                    {seriesItems?.map((seriesItem, seriesIndex) => {
                                        const dropDownId = `dropdown-${seriesIndex}`;
                                        return <div key={seriesIndex} className='post-purchase-bv-widget__series-item-wrapper'>
                                            <Image image={seriesItem?.fields?.image} className='post-purchase-bv-widget__series-item-image' />
                                            <div className={'post-purchase-bv-widget__dropdown'}>
                                                <button tabIndex={0} className="post-purchase-bv-widget__dropbtn" onClick={() => focusElement(dropDownId, PostPurchaseConstants?.dropdownClass)} >{seriesItem?.fields?.name}<i className="post-purchase-bv-widget__down-arrow"></i></button>
                                                <div id={dropDownId} className="post-purchase-bv-widget__dropdown-content">
                                                    {seriesItem?.fields?.productLinks?.map((link, linkIndex) => {
                                                        const linkAttribs = {};
                                                        const isLinkDisable = link?.fields?.url === PostPurchaseConstants.noSlug;
                                                        if (isLinkDisable) {
                                                            linkAttribs.disabled = true;
                                                        }
                                                        return <button tabIndex={0} onClick={()=> openWarPopup(link?.fields?.id)} key={linkIndex} className={`post-purchase-bv-widget__dropdown-link event_product_review ${isLinkDisable ? 'post-purchase-bv-widget__dropdown-link-disable' : ''}`} data-action-detail={link?.fields?.title} {...linkAttribs}>
                                                            <span>{link?.fields?.title}</span>
                                                            <Image image={checkmarkIcon} className={isLinkDisable ? 'post-purchase-bv-widget__dropdown-link-disable' : ''} />
                                                        </button>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </Image>
                    </div>
                    <div className='post-purchase-bv-widget__war'>
                        {reviewAndRatingId && <>
                            <div data-bv-show={UserQuoteConstants.ratingSummary} data-bv-product-id={reviewAndRatingId}></div>
                            <div data-bv-show={UserQuoteConstants.reviews} data-bv-product-id={reviewAndRatingId}></div>
                            <div data-bv-show={UserQuoteConstants.questions} data-bv-product-id={reviewAndRatingId}></div>
                        </>}
                    </div>
                </div>
            </Modal>
        </section>);
}

PostPurchaseWriteReviewSection.propTypes = {
    document: PropTypes.object
};

export default mediaQueryHOC(PostPurchaseWriteReviewSection);
// This export is for unit testing (do not remove) :
export const PostPurchaseWriteReviewSectionTest = PostPurchaseWriteReviewSection;
