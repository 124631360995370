import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import { isObjectEmpty } from '../../adapters/helpers/Utils';
import Block from '../../adapters/helpers/Block';
import { MetaConstants } from '../../adapters/helpers/Constants';

const SwitchTab = (props) => {
  const document = props?.document;
  const contentDetails = document?.fields?.contentDetails;
  const [selectedSwitchTypeIndex, setSelectedSwitchTypeIndex] = useState(0);
  const switchType =
    Array.isArray(contentDetails) &&
    contentDetails.length > selectedSwitchTypeIndex &&
    contentDetails[selectedSwitchTypeIndex];
  let contents = switchType?.fields?.contents;
  const anchorId = document?.fields?.anchorId;
  const className = document?.fields?.classNames;
  const isMultipleTabs = document?.fields?.isMultipleTabs;

  for (let i = 0; i < contents?.length; i++) {
    if (contents?.[i]?.fields?.contentType === 'switchTabs') {
      if (!contents[i].fields.onClickCallback) {
        contents[i].fields.onClickCallback = props?.onClickCallback;
      }
    } else if (contents?.[i]?.fields?.contentType === 'productRecommendBlock') {
      if (!contents[i].fields.onClickCallback) {
        contents[i].fields.onClickCallback = props?.onClickCallback
          ? props?.onClickCallback
          : props?.document?.fields?.onClickCallback;
      }
    }
  }

  return (
    <section
      className={`ob-switch-tab-block ${className ? className : ''}`}
      id={anchorId}
    >
      {document?.fields?.title && (
        <h3 className='ob-switch-tab-title'>{document?.fields?.title}</h3>
      )}
      {document?.fields?.subTitle && (
        <h2 className='ob-switch-tab-sub-title'>
          {document?.fields?.subTitle}
        </h2>
      )}
      {!isMultipleTabs
        ? Array.isArray(contentDetails) && (
            <ul role='tablist' className={'ob-switch-tab__content-details'}>
              {contentDetails &&
                contentDetails.map((switchtab, index) => {
                  const swtichTypeCornerClass =
                    index === 0
                      ? 'ob-switch-tab__content-details-left'
                      : index === contentDetails.length - 1
                      ? 'ob-switch-tab__content-details-right'
                      : '';
                  return (
                    <li role='tab'>
                      <div
                        className={`ob-switch-tab__content-details-type ${
                          index === selectedSwitchTypeIndex
                            ? 'ob-switch-tab__active-switch'
                            : ''
                        } ${swtichTypeCornerClass}`}
                        onClick={() => setSelectedSwitchTypeIndex(index)}
                        key={index}
                        tabIndex={index}
                      >
                        {switchtab?.fields?.label}
                      </div>
                    </li>
                  );
                })}
            </ul>
          )
        : Array.isArray(contentDetails) && (
            <ul
              role='tablist'
              className={'ob-switch-tab-multiple__content-details'}
            >
              {contentDetails &&
                contentDetails.map((switchtab, index) => {
                  return (
                    <li role='tab'>
                      <a
                        className={`ob-switch-tab__content-details-type ${
                          index === selectedSwitchTypeIndex
                            ? 'ob-switch-tab__active-switch'
                            : ''
                        }`}
                        onClick={() => setSelectedSwitchTypeIndex(index)}
                        key={index}
                        tabIndex={index}
                      >
                        <img
                          className='switch-tab-image-asset'
                          src={
                            MetaConstants.https +
                            switchtab?.fields?.switchTabAsset?.fields?.file?.url
                          }
                        />
                        <div className='switch-tab-label'>
                          {switchtab?.fields?.label}
                        </div>
                      </a>
                    </li>
                  );
                })}
            </ul>
          )}
      {!isObjectEmpty(contents) &&
        new Block(contents).renderSpecifiedBlock(contents)}
    </section>
  );
};

SwitchTab.propTypes = {
  document: PropTypes.object,
};

export default mediaQueryHOC(SwitchTab);
