import React, { useEffect, useState, useRef, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import C from 'classnames';
import { getColorStyle, isIOS, openTo, handleFindInlineRatingElement } from '../../adapters/helpers/Utils';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import { Constants, ContentConstants, PrePurchaseConstants } from '../../adapters/helpers/Constants';
import Heading from '../Heading/Heading';
import Image from '../Image/Image';
import { getReviewAndRatingId, addBVScript, addBVCollectionPageCallback, updateRatingTextCallback } from '../../adapters/helpers/Utils';
import ImageFactory from '../../adapters/cloudinary/ImageFactory';
import { Markdown } from '../MarkdownText/MarkdownText';
import Icon from '../Icon/Icon';
import ObLink from '../ObLink/ObLink';
import Row from './CompareRow';
import Cell from './CompareCell';
import TitleInfoIcon from './TitleInfoIcon';
import { getSearchQueryParameter } from '../Janrain/Janrain/janrainForms/Janrain.helpers';
import Modal from 'react-modal';
import List from '../Janrain/components/List';
import { stripHtml } from '../../adapters/helpers/Utils';

const MINIMUM_WIDTH_MOBILE_VW = 40;
const PADDING_HORIZONTAL_MOBILE_PX = 0;

const PrePurchaseResult = (props) => {

    const title = props?.document?.fields?.title;
    const series = props?.document?.fields?.series;
    const colorsLabel = props?.document?.fields?.colorsLabel;
    const startingAtPriceLabel = props?.document?.fields?.startingAtPriceLabel;
    const compareChartTitle = props?.document?.fields?.compareChartTitle;
    const learnMoreCtaText = props?.document?.fields?.learnMoreCtaText;
    const starRatingIcon = props?.document?.fields?.starRatingIcon;
    const customerReviews = props?.document?.fields?.customerReviews;
    const seeMoreText = props?.document?.fields?.seeMoreText;
    const seeLessText = props?.document?.fields?.seeLessText;
    const seeAccessoriesText = props?.document?.fields?.seeAccessoriesText;
    const hideAccessoriesText = props?.document?.fields?.hideAccessoriesText;
    const firstBestMatchText = props?.document?.fields?.firstBestMatchText;
    const secondBestMatchText = props?.document?.fields?.secondBestMatchText;
    const thirdBestMatchText = props?.document?.fields?.thirdBestMatchText;
    const prePurchaseCompareHeaders = props?.document?.fields?.prePurchaseCompareHeaders;
    const infoIcon = props?.document?.fields?.infoIcon;
    const outOfRatingValue = props?.document?.fields?.outOfRatingValue;
    const mobileNavIcons = props?.document?.fields?.mobileNavIcons;
    const toolTipArrowIcon = props?.document?.fields?.toolTipArrowIcon;
    const upcomingProducts = props?.document?.fields?.upcomingProducts;
    const compareResultTitleLevel = props?.document?.fields?.compareResultTitleLevel;
    const compareChartTitleLevel = props?.document?.fields?.compareChartTitleLevel;
    const pdpTarget = props?.document?.fields?.pdpTarget || Constants.blank;
    const navLeftIcon = mobileNavIcons[0];
    const navRightIcon = mobileNavIcons[1];
    const matchedResults = [firstBestMatchText, secondBestMatchText, thirdBestMatchText];
    const [jebbitData, setJebbitData] = useState([]);
    const [selectedVariantsIndex, setSelectedVariants] = useState([0, 0, 0]);
    const [windowWidth, setWindowWidth] = useState(ContentConstants.defaultWidth);
    const [showMore, setShowMore] = useState(false);
    const [showAccessories, setShowAccessories] = useState(false);
    const [shortlistedProductsCopy, setShortlistedProductsCopy] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [currentPage, setCurrentPageIndex] = useState(0);
    const [leftPageIndex, setLeftPageIndex] = useState(0);
    const [rightPageIndex, setRightPageIndex] = useState(0);
    const [numPages, setNumPages] = useState(0);
    const [iOS, setIsIOS] = useState(false);
    const leftFadeRef = useRef(null);
    const rightFadeRef = useRef(null);
    const products = [];
    const isMobileView = windowWidth <= Constants.desktopViewStart;

    const [isModalOpen, setModelOpen] = useState(false);
    const [compareModalIndex, setCompareModalIndex] = useState(0);
    const [selectedBrushIndex, setSelectedBrushIndex] = useState([0, 0]);
    const closeModalTimeoutMS = 250;

    const customStyles = {
        overlay: {
            boxShadow: 'rgba(0, 0, 0, 0.5) 0 2px 4px',
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflowX: 'hidden',
        },
        content: {
            position: 'static',
            display: 'flex'
        }
    };


    series?.forEach(serie => {
        serie?.fields?.products?.forEach(product => {
            product?.fields?.jebbitId && product?.fields?.isProductLaunched != false && products.push({ ...product });
        })
    });
    upcomingProducts?.forEach(product => {
        product?.fields?.jebbitId && product?.fields?.isProductLaunched != false && products.push({ ...product });
    });

    const brushList = products.map(product => {
        const productLabel = product.fields?.displayNamePrePurchaseProductList;
        return comp(productLabel);
    });

    useEffect(() => {
        const productId1 = getSearchQueryParameter(PrePurchaseConstants.QUERY_PARAM_PRODUCT1);
        const productId2 = getSearchQueryParameter(PrePurchaseConstants.QUERY_PARAM_PRODUCT2);
        const productId3 = getSearchQueryParameter(PrePurchaseConstants.QUERY_PARAM_PRODUCT3);
        const hasJebbitProductIdQueryParam = productId1 || productId2 || productId3;
        if (hasJebbitProductIdQueryParam) {
            const jebbitDataQueryParam = [];
            productId1 && jebbitDataQueryParam.push({ id: productId1 });
            productId2 && jebbitDataQueryParam.push({ id: productId2 });
            productId3 && jebbitDataQueryParam.push({ id: productId3 });
            setJebbitData(jebbitDataQueryParam);
        } else {
            const jebbitData = JSON.parse(sessionStorage.getItem(PrePurchaseConstants.jebbitData));
            if (Array.isArray(jebbitData)) {
                setJebbitData(jebbitData.slice(0, PrePurchaseConstants.MAX_COL_DESKTOP));
            }
        }
    }, []);

    useEffect(() => {
        const selectedProducts = [];
        const selectedProductsIndex = [];
        jebbitData?.forEach(jebbitPayload => {
            for (let productIndex = 0; productIndex < products?.length; productIndex++) {
                if (jebbitPayload?.id === products[productIndex]?.fields?.jebbitId) {
                    selectedProductsIndex.push(productIndex);
                    selectedProducts.push(products[productIndex]);
                    break;
                }
            }
        });
        selectedProductsIndex.length > 0 && setSelectedBrushIndex(selectedProductsIndex.slice(0, PrePurchaseConstants.MAX_COL_MOBILE));
        setSelectedProducts(selectedProducts);
        setShortlistedProductsCopy([...selectedProducts]);
        const numPages = (selectedProducts || []).length;
        setNumPages(numPages);
        setCurrentPageIndex(0);
        calculatePrevNextPageIndex(0, numPages);
    }, [jebbitData, jebbitData?.length]);


    function comp(name) {
        return (
            <div className="product-name">{name}</div>
        )
    }

    const isValidBrushIndex = (selectedIndex) => {
        return selectedIndex > -1;
    }

    const updateBrushIndex = (choiceIndex) => {
        selectedBrushIndex[compareModalIndex] = choiceIndex;
        setSelectedBrushIndex([...selectedBrushIndex]);
        if (isValidBrushIndex(choiceIndex)) {
            const productValue = products[choiceIndex]?.fields?.jebbitId;
            setSelectedProduct(compareModalIndex, productValue);
        }
        closeModal();
    }

    const openBrushListModel = (e, compareIndex) => {
        e.preventDefault();
        setCompareModalIndex(compareIndex);
        if (!isEmptyBrushList()) {
            setModelOpen(true);
        }
    }
    const closeModal = (e) => {
        e && e?.preventDefault();
        setModelOpen(false);
    }

    const isEmptyBrushList = () => brushList?.length === 0;

    /**
     * Todo Accessibility: comment the calling method and 
     * once PGONE team confirm uncomment the calling method.
     */
    useEffect(() => {
        addBVScript();
        addBVCollectionPageCallback(outOfRatingValue);
        updateRatingTextCallback(outOfRatingValue);
    }, []);

    useEffect(() => {
        document.addEventListener('DOMContentLoaded', handleFindInlineRatingElement(PrePurchaseConstants?.WAR_REVIEW_LALBEL_UPDATE_TIMEOUT));
    }, []);

    function handleResize() {
        setWindowWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        setIsIOS(isIOS());
        return (() => {
            window.removeEventListener('resize', handleResize)
        })
    }, []);

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    const handleShowAccessories = () => {
        setShowAccessories(!showAccessories);
    };

    const alignCenter = () => {
        if (isMobileView) {
            setTimeout(() => {
                const scrollLeft = (document.body.scrollWidth - document.body.clientWidth) / 2
                document.body.scrollLeft = scrollLeft;
                window.scroll(scrollLeft, window.pageYOffset)
            }, PrePurchaseConstants.CENTER_ALGN_TIMEOUT);
        }
    };

    const onChartScroll = useCallback((event) => {
        if (leftFadeRef.current || rightFadeRef.current) {
            let scrollRatio = event.target.scrollLeft / (event.target.scrollWidth - event.target.offsetWidth);
            scrollRatio = Math.max(0, scrollRatio);
            scrollRatio = Math.min(1, scrollRatio);

            if (leftFadeRef.current) {
                leftFadeRef.current.style.opacity = scrollRatio;
            }

            if (rightFadeRef.current) {
                rightFadeRef.current.style.opacity = 1 - scrollRatio;
            }
        }
        alignCenter();
    }, []);

    const setSelectedProduct = (selectedProductIndex, jebbitId, type) => {
        const product = products.find(product => product?.fields?.jebbitId === jebbitId);
        const updatedShortlistedProducts = shortlistedProductsCopy.map((currentProduct, productIndex) => {
            if (selectedProductIndex === productIndex) {
                return product;
            } else {
                return currentProduct;
            }
        })
        setShortlistedProductsCopy(updatedShortlistedProducts);
        alignCenter();
        type == PrePurchaseConstants.typeOnChange && window.dataLayer.push({
            'event': 'customEvent',
            'GAeventCategory': 'event_informational_action',
            'GAeventAction': 'event_product_compare',
            'GAeventLabel': `${product?.fields?.prePurchaseProductName}`,
            'GAeventValue': 0,
            'GAeventNonInteraction': false
        });
    };

    const showModes = (compareModes) => {
        return showMore ?
            <>
                <Row className={isMobileView ? 'pre-purchase-compare-chart__compare-point-details-row' : `${shortlistedProductsCopy.length == 3 ? 'pre-purchase-compare-chart__compare-point-details-row' : 'rows-with-column-two'}`}>
                    <div className='extra-column'></div>
                    {
                        compareModes && compareModes.map((modes, compareModeIndex) => {
                            return <Cell tabIndex={0} key={compareModeIndex} className='pre-purchase-compare-chart__compare-point-mode-detail'>
                                <ul>
                                    {
                                        modes && modes.map((modeData, modeIndex) => <li key={modeIndex}>
                                            <Markdown source={modeData?.fields?.mode} />
                                        </li>)
                                    }
                                </ul>
                                <div className='cell-break'><br /><br /><br /><br /><br /></div>
                            </Cell>
                        })
                    }
                </Row>
                <div className='pre-purchase-compare-chart__full-width-cell'>
                    <ObLink ariaExpanded={true} className="primaryGrey pre-purchase-compare-chart__see-more event_button_click" dataActionDetail={seeLessText} tag={PrePurchaseConstants.button} onClick={handleShowMore}>
                        <Icon name={PrePurchaseConstants.minus6} arialable=" " roundedIcon={PrePurchaseConstants.blue} viewBox={6} />
                        {seeLessText}
                        <span className="visuallyhidden">{PrePurchaseConstants.modes}</span>
                    </ObLink>
                </div>
            </>
            : <div className='pre-purchase-compare-chart__full-width-cell'>
                <ObLink ariaExpanded={false} className="primaryGrey pre-purchase-compare-chart__see-more event_button_click" dataActionDetail={seeMoreText} tag={PrePurchaseConstants.button} onClick={handleShowMore}>
                    <Icon name={PrePurchaseConstants.plus} arialable=" " roundedIcon={PrePurchaseConstants.blue} viewBox={10} />
                    {seeMoreText}
                    <span className="visuallyhidden">{PrePurchaseConstants.modes}</span>
                </ObLink>
            </div>

    };

    const showAccessorieDetails = (compareAccessories) => {
        return <>
            {showAccessories ?
                <>
                    <Row className={isMobileView ? 'pre-purchase-compare-chart__compare-point-details-row' : `${shortlistedProductsCopy.length == 3 ? 'pre-purchase-compare-chart__compare-point-details-row' : 'rows-with-column-two'}`}>
                        <div className='extra-column'></div>
                        {
                            compareAccessories && compareAccessories.map((accessories, compareAccessorieIndex) => {
                                return <Cell tabIndex={0} className='pre-purchase-compare-chart__accessories' key={compareAccessorieIndex}>
                                    <div className='pre-purchase-compare-chart__accessories-content-wrapper'>
                                        <Markdown source={accessories} />
                                    </div>
                                    <div className='cell-break'><br /><br /><br /></div>
                                </Cell>
                            })
                        }
                    </Row>
                    <div className='pre-purchase-compare-chart__full-width-cell'>
                        <ObLink ariaExpanded={true} className="primaryGrey pre-purchase-compare-chart__see-more pre-purchase-compare-chart__hide-accessories event_button_click" dataActionDetail={seeLessText} tag={PrePurchaseConstants.button} onClick={handleShowAccessories}>
                            <Icon name={PrePurchaseConstants.minus6} arialable=" " roundedIcon={PrePurchaseConstants.blue} viewBox={6} />
                            {hideAccessoriesText}
                            <span className="visuallyhidden">{PrePurchaseConstants.accessories}</span>
                        </ObLink>
                    </div>
                </>
                : <div className='pre-purchase-compare-chart__full-width-cell'>
                    <ObLink ariaExpanded={false} className="primaryGrey pre-purchase-compare-chart__see-more pre-purchase-compare-chart__see-more-accessories event_button_click" dataActionDetail={seeMoreText} tag={PrePurchaseConstants.button} onClick={handleShowAccessories}>
                        <Icon name={PrePurchaseConstants.plus} arialable=" " roundedIcon={PrePurchaseConstants.blue} viewBox={10} />
                        {seeAccessoriesText}
                        <span className="visuallyhidden">{PrePurchaseConstants.accessories}</span>
                    </ObLink>
                </div>
            }</>
    };

    function comparisonMobileView(isCrossWrapper) {
        const compareModes = [];
        const compareAccessories = [];
        const shortlistedProductsCopyMobile = Array.isArray(shortlistedProductsCopy) && shortlistedProductsCopy.slice(0, PrePurchaseConstants.MAX_COL_MOBILE);
        const singleProductCompare = numPages === 1;
        return (
            <div className={`pre-purchase-compare-chart__wrapper ${isCrossWrapper ? 'pre-purchase-compare-chart__cross-wrapper' : ''}`}>
                {Array.isArray(shortlistedProductsCopyMobile) && <div className='pre-purchase-compare-chart__scroll-wrapper'>
                    <div className='pre-purchase-compare-chart__table'
                        role={PrePurchaseConstants.table}>
                        <div role='rowgroup'>
                            <Row className='feature-rows-mobile'>
                                {shortlistedProductsCopyMobile.map((shortlistedProduct, productCompareIndex) => {
                                    const asset = shortlistedProduct?.fields?.productVariants ? shortlistedProduct?.fields?.productVariants?.[0]?.fields?.mainAsset : shortlistedProduct?.fields?.mainAsset;
                                    const assetId = asset?.fields?.asset?.fields?.file?.url;
                                    let altText = asset?.fields?.alternateText;
                                    let imageSrc = assetId && ImageFactory.buildContentfullImageUrlByHeight(assetId, '400');
                                    return (
                                        <Cell key={productCompareIndex} className={`pre-purchase-compare-chart__header ${productCompareIndex === 0 ? '' : 'pre-purchase-compare-chart__non-header-content'}`} role={PrePurchaseConstants.columnheader}>
                                            <Fragment>
                                                <button tabIndex={0} className="pre-purchase-compare-chart__dropbtn" onClick={(event) => openBrushListModel(event, productCompareIndex)} >
                                                    <span>{shortlistedProduct?.fields?.displayNamePrePurchaseProductList}</span><i className="pre-purchase-compare-chart__down-arrow" />
                                                </button>
                                                <div className='pre-purchase-compare-chart__image'>
                                                    <img className='pre-purchase-compare-chart__product-image' role='presentation' src={imageSrc} />
                                                </div>
                                            </Fragment>
                                        </Cell>
                                    )
                                })}
                            </Row>
                        </div>
                        <div role='rowgroup'>
                            {prePurchaseCompareHeaders.map((header, headerIndex) => {
                                const isModes = header?.fields?.isModes;
                                const isAccessories = header?.fields?.isAccessories;
                                return <>
                                    {<Row key={`feature-row__${headerIndex}`} className='feature-rows-mobile'>
                                        {shortlistedProductsCopyMobile.map((product, productIndex) => {
                                            const comparePoints = product?.fields?.prePurchaseComparePoints
                                            const headerText = header?.fields?.title;
                                            const toolTipTitle = header?.fields?.toolTipTitle;
                                            const toolTipText = header?.fields?.toolTipText;
                                            const image = comparePoints?.[headerIndex]?.fields?.image;
                                            const text = comparePoints?.[headerIndex]?.fields?.text;
                                            const hasShowMore = isModes || isAccessories;
                                            const isNoInfoIcon = hasShowMore || comparePoints?.[headerIndex]?.fields?.isNoInfoIcon;
                                            isModes && compareModes.push(comparePoints?.[headerIndex]?.fields?.modes);
                                            isAccessories && compareAccessories.push(comparePoints?.[headerIndex]?.fields?.accessories);
                                            const isRowHeader = productIndex === 0;

                                            return <Cell tabIndex={0} key={`${headerText}-${productIndex}`} className='pre-purchase-compare-chart__feature-cell' role={PrePurchaseConstants.cell}>
                                                <div className='pre-purchase-compare-chart__cell-content-wrapper'>
                                                    <div className='pre-purchase-compare-chart__row-header-title'>{isRowHeader ? headerText : ''}</div>
                                                    {text && <div tabIndex={0} className={`pre-purchase-compare-chart__feature-cell-content-wrapper ${isRowHeader ? 'pre-purchase-compare-chart__header-content' : 'pre-purchase-compare-chart__non-header-content'} ${singleProductCompare ? 'pre-purchase-compare-chart__single-product-compare' : ''}`}>
                                                        {image && <Markdown source={image} />}
                                                        <TitleInfoIcon titleElement={text} infoIcon={isNoInfoIcon ? null : infoIcon} toolTipTitle={toolTipTitle} toolTipText={toolTipText} arrowIcon={toolTipArrowIcon} />
                                                    </div>}
                                                    {isModes && !showMore && <div className='cell-break'><br /><br /></div>}
                                                    {isAccessories && <div className='cell-break'><br /><br /><br /></div>}
                                                </div>
                                            </Cell>
                                        })}
                                    </Row>}
                                    {isModes && showModes(compareModes)}
                                    {isAccessories && showAccessorieDetails(compareAccessories)}
                                </>
                            }
                            )}
                        </div>
                        <div role='rowgroup'>
                            <Row className='pre-purchase-compare-chart__learn-more-cta-row'>
                                {shortlistedProductsCopyMobile.map((shortlistedProduct, productIndex) => {
                                    const ctaAttribs = {};
                                    if (shortlistedProduct?.fields?.slug?.fields?.slug === PrePurchaseConstants.noSlug) {
                                        ctaAttribs.disabled = true;
                                    }
                                    return <Cell key={`${PrePurchaseConstants.buyNowCta}-${productIndex}`} role={PrePurchaseConstants.cell}>
                                        {shortlistedProduct?.fields?.comingSoonLink ? <ObLink
                                            href={shortlistedProduct?.fields?.comingSoonLink}
                                            isExternalLink={true}
                                            className={'ob-button pre-purchase-compare-chart__learn-more-cta event_external_link'}
                                            dataActionDetail={PrePurchaseConstants.dataActionDetail} >
                                            <span>{learnMoreCtaText}</span>
                                        </ObLink> :
                                            <button
                                                className={'ob-button pre-purchase-compare-chart__learn-more-cta event_button_click'}
                                                onClick={() => openTo(shortlistedProduct?.fields?.slug?.fields?.slug, pdpTarget)}
                                                data-action-detail={PrePurchaseConstants.dataActionDetail}
                                                {...ctaAttribs}
                                            >
                                                <span>{learnMoreCtaText}</span>
                                            </button>}
                                    </Cell>
                                })
                                }
                            </Row>
                        </div>
                        <div role='rowgroup'>
                            <Row className='pre-purchase-compare-chart__costomer-rating-and-review'>
                                {shortlistedProductsCopyMobile.map((product, productIndex) => {
                                    const reviewText = product?.fields?.prePurchaseCustomerReview;
                                    // Master Product id containing the reviews of all the product in his family
                                    const reviewAndRatingId = getReviewAndRatingId(product);
                                    return <Cell key={`${PrePurchaseConstants.customerReview}-${productIndex}`} className={`pre-purchase-compare-chart__rating-and-review ${reviewText ? '' : 'pre-purchase-compare-chart__disable-review-icon'}`} role={PrePurchaseConstants.cell}>
                                        <Image image={starRatingIcon} />
                                        <p className='pre-purchase-compare-chart__rating-text'>
                                            {product?.fields?.prePurchaseCustomerReview && <q>{product?.fields?.prePurchaseCustomerReview}</q>}
                                        </p>
                                        <p className='pre-purchase-compare-chart__rating-value'>{product?.fields?.starRatingValue}</p>
                                        <p className='pre-purchase-compare-chart__customer-review-text'>{customerReviews}</p>
                                        <hr />
                                        <div itemScope itemType={PrePurchaseConstants.itemType} className={'pre-purchase-compare-chart__customer-star-rating'}>
                                            <meta itemProp={product?.fields?.name} content={PrePurchaseConstants.ProductName} name={product?.fields?.name} />
                                            <div data-bv-show={PrePurchaseConstants.ratingSummary} data-bv-product-id={reviewAndRatingId} />
                                        </div>
                                    </Cell>
                                })
                                }
                            </Row>
                        </div>
                    </div>
                </div>}
            </div>
        )
    }

    function comparisonDesktopView() {
        const compareModes = [];
        const compareAccessories = [];
        return (
            <div className='pre-purchase-compare-chart__wrapper'>
                {Array.isArray(shortlistedProductsCopy) && <div onScroll={onChartScroll} className='pre-purchase-compare-chart__scroll-wrapper'>
                    <div className='pre-purchase-compare-chart__table'
                        role={PrePurchaseConstants.table}
                        style={{ width: `calc(${MINIMUM_WIDTH_MOBILE_VW * shortlistedProductsCopy.length}vw + ${2 * PADDING_HORIZONTAL_MOBILE_PX}px)` }}>
                        <div role='rowgroup'>
                            <Row className={`${shortlistedProductsCopy.length == 3 ? 'feature-rows' : 'rows-with-column-two'}`}>
                                <div className='extra-column-desktop'></div>
                                {shortlistedProductsCopy.map((shortlistedProduct, productCompareIndex) => {
                                    const asset = shortlistedProduct?.fields?.productVariants ? shortlistedProduct?.fields?.productVariants?.[0]?.fields?.mainAsset : shortlistedProduct?.fields?.mainAsset;
                                    const assetId = asset?.fields?.asset?.fields?.file?.url;
                                    let altText = asset?.fields?.alternateText;
                                    let imageSrc = assetId && ImageFactory.buildContentfullImageUrlByHeight(assetId, '400');
                                    return (
                                        <Cell key={productCompareIndex} className='pre-purchase-compare-chart__header' role={PrePurchaseConstants.columnheader}>
                                            <Fragment>
                                                <select
                                                    required={true}
                                                    aria-label={PrePurchaseConstants.ariaLabel}
                                                    name={'spotlight_experience_country'}
                                                    className={'pre-purchase-compare-chart__select-brush'}
                                                    data-reset-country-code={true}
                                                    onChange={event => setSelectedProduct(productCompareIndex, event?.target?.value, PrePurchaseConstants.typeOnChange)}
                                                    onBlur={event => setSelectedProduct(productCompareIndex, event?.target?.value, PrePurchaseConstants.typeOnBlur)}>
                                                    {
                                                        products.map((product, productIndex) => {
                                                            const productValue = product.fields?.jebbitId;
                                                            const productLabel = product.fields?.displayNamePrePurchaseProductList;
                                                            const selected = product?.fields?.jebbitId === shortlistedProduct?.fields?.jebbitId;
                                                            return (
                                                                <option value={productValue} selected={selected}
                                                                    key={`selectOption-${productIndex}`} dangerouslySetInnerHTML={{ __html: productLabel }} />
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <div className='pre-purchase-compare-chart__image'>
                                                    <img className='pre-purchase-compare-chart__product-image' src={imageSrc} role='presentation' />
                                                </div>
                                            </Fragment>
                                        </Cell>
                                    )
                                })}
                            </Row>
                        </div>
                        <div role='rowgroup'>
                            {prePurchaseCompareHeaders.map((header, headerIndex) => {
                                const isModes = header?.fields?.isModes;
                                const isAccessories = header?.fields?.isAccessories;
                                return <>
                                    {<Row key={`feature-row__${headerIndex}`} className={`${shortlistedProductsCopy.length == 3 ? 'feature-rows' : 'rows-with-column-two'}`}>
                                        {shortlistedProductsCopy.map((product, productIndex) => {
                                            const comparePoints = product?.fields?.prePurchaseComparePoints
                                            const headerText = header?.fields?.title;
                                            const toolTipTitle = header?.fields?.toolTipTitle;
                                            const toolTipText = header?.fields?.toolTipText;
                                            const image = comparePoints?.[headerIndex]?.fields?.image;
                                            const text = comparePoints?.[headerIndex]?.fields?.text;
                                            const hasShowMore = isModes || isAccessories;
                                            const isNoInfoIcon = hasShowMore || comparePoints?.[headerIndex]?.fields?.isNoInfoIcon;
                                            isModes && compareModes.push(comparePoints?.[headerIndex]?.fields?.modes);
                                            isAccessories && compareAccessories.push(comparePoints?.[headerIndex]?.fields?.accessories);
                                            const isRowHeader = productIndex === 0;
                                            return (
                                                <>
                                                    {isRowHeader && <div tabIndex={0} className='pre-purchase-compare-chart__row-header-title'>{headerText}</div>}
                                                    <Cell key={`${headerText}-${productIndex}`} className='pre-purchase-compare-chart__feature-cell' role={PrePurchaseConstants.cell}>
                                                        {text && <div tabIndex={0} className={`pre-purchase-compare-chart__feature-cell-content-wrapper ${isRowHeader ? 'pre-purchase-compare-chart__header-content' : 'pre-purchase-compare-chart__non-header-content'} ${numPages === 1 ? 'pre-purchase-compare-chart__header-content-single-column' : ''}`}>
                                                            {image && <Markdown source={image} />}
                                                            <TitleInfoIcon titleElement={text} infoIcon={isNoInfoIcon ? null : infoIcon} toolTipTitle={toolTipTitle} toolTipText={toolTipText} arrowIcon={toolTipArrowIcon} isLessthanThreeProducts={!isMobileView && shortlistedProductsCopy.length < 3} />
                                                        </div>}
                                                        {isModes && !showMore && <div className='cell-break'><br /><br /><br /></div>}
                                                        {isAccessories && !showAccessories && <div className='cell-break'><br /><br /><br /></div>}
                                                    </Cell>
                                                </>
                                            )
                                        })}
                                    </Row>}
                                    {isModes && showModes(compareModes)}
                                    {isAccessories && showAccessorieDetails(compareAccessories)}
                                </>
                            }
                            )}
                        </div>
                        <div role='rowgroup'>
                            <Row className={`${shortlistedProductsCopy.length == 3 ? 'pre-purchase-compare-chart__learn-more-cta-row' : 'rows-with-column-two'}`}>
                                <div className='extra-column-desktop'></div>
                                {shortlistedProductsCopy.map((shortlistedProduct, productIndex) => {
                                    const ctaAttribs = {};
                                    if (shortlistedProduct?.fields?.slug?.fields?.slug === PrePurchaseConstants.noSlug) {
                                        ctaAttribs.disabled = true;
                                    }
                                    return <Cell key={`${PrePurchaseConstants.buyNowCta}-${productIndex}`} role={PrePurchaseConstants.cell}>
                                        {
                                            shortlistedProduct?.fields?.comingSoonLink ? <ObLink
                                                href={shortlistedProduct?.fields?.comingSoonLink}
                                                isExternalLink={true}
                                                className={'ob-button pre-purchase-compare-chart__learn-more-cta event_external_link'}
                                                dataActionDetail={PrePurchaseConstants.dataActionDetail} >
                                                <span>{learnMoreCtaText}</span>
                                            </ObLink> :
                                                <button
                                                    className={'ob-button pre-purchase-compare-chart__learn-more-cta event_button_click'}
                                                    onClick={() => openTo(shortlistedProduct?.fields?.slug?.fields?.slug, pdpTarget)}
                                                    data-action-detail={PrePurchaseConstants.dataActionDetail}
                                                    {...ctaAttribs}
                                                >
                                                    <span>{learnMoreCtaText}</span>
                                                </button>
                                        }
                                    </Cell>
                                })
                                }
                            </Row>
                        </div>
                        <div role='rowgroup'>
                            <Row className={`${shortlistedProductsCopy.length == 3 ? 'pre-purchase-compare-chart__costomer-rating-and-review' : 'rows-with-column-two'}`}>
                                <div className='extra-column-desktop'></div>
                                {shortlistedProductsCopy.map((product, productIndex) => {
                                    const reviewText = product?.fields?.prePurchaseCustomerReview;
                                    // Master Product id containing the reviews of all the product in his family
                                    const reviewAndRatingId = getReviewAndRatingId(product);
                                    return <Cell key={`${PrePurchaseConstants.customerReview}-${productIndex}`} className={`pre-purchase-compare-chart__rating-and-review ${reviewText ? '' : 'pre-purchase-compare-chart__disable-review-icon'}`} role={PrePurchaseConstants.cell}>
                                        <Image image={starRatingIcon} />
                                        <p className='pre-purchase-compare-chart__rating-text'>
                                            {product?.fields?.prePurchaseCustomerReview && <q>{product?.fields?.prePurchaseCustomerReview}</q>}
                                        </p>
                                        <p className='pre-purchase-compare-chart__rating-value'>{product?.fields?.starRatingValue}</p>
                                        <p className='pre-purchase-compare-chart__customer-review-text'>{customerReviews}</p>
                                        <hr />
                                        <div itemScope itemType={PrePurchaseConstants.itemType} className={'pre-purchase-compare-chart__customer-star-rating'}>
                                            <meta itemProp={product?.fields?.name} content={PrePurchaseConstants.ProductName} name={product?.fields?.name} />
                                            <div data-bv-show={PrePurchaseConstants.ratingSummary} data-bv-product-id={reviewAndRatingId} />
                                        </div>
                                    </Cell>
                                })
                                }
                            </Row>
                        </div>
                    </div>
                </div>}
                <div className='pre-purchase-compare-chart__fade-left' ref={leftFadeRef} />
                <div className='pre-purchase-compare-chart__fade-right' ref={rightFadeRef} />
            </div>
        )
    }

    const handleClick = event => {
        let activePageIndex = currentPage;
        const clickedButton = event.currentTarget.dataset.controlButton;
        if (clickedButton === 'next' && activePageIndex + 1 < numPages) {
            activePageIndex = activePageIndex + 1;
        } else if (clickedButton === 'prev' && activePageIndex - 1 >= 0) {
            activePageIndex = activePageIndex - 1;
        }
        calculatePrevNextPageIndex(activePageIndex, numPages);
        setCurrentPageIndex(activePageIndex);
    };

    const calculatePrevNextPageIndex = (currentPageIndex, totalPages) => {
        let leftIndex = currentPageIndex - 1;
        let rightIndex = currentPageIndex + 1;
        if (leftIndex < 0) {
            leftIndex = totalPages - 1;
        }
        if (rightIndex >= totalPages) {
            rightIndex = 0;
        }
        setLeftPageIndex(leftIndex);
        setRightPageIndex(rightIndex);
    };

    const displayProductMobileView = () => {
        const product = selectedProducts[currentPage];
        const productIndex = currentPage;
        const reviewAndRatingId = getReviewAndRatingId(product);
        const { productVariants, prePurchaseProductName, prePurchaseProductDescription, prePurchaseStartingAtPrice, prePurchaseBestMatchProductTip } = product?.fields;
        const productVariant = productVariants[selectedVariantsIndex[productIndex]];
        const productImage = productVariant?.fields?.mainAsset || product?.fields?.mainAsset;
        const priceStartsAt = prePurchaseStartingAtPrice && prePurchaseStartingAtPrice;
        const bestMatch = matchedResults[productIndex];
        const ctaAttribs = {};
        const eventLabel = prePurchaseProductName.replace(/\s/g, "")
        if (product?.fields?.slug?.fields?.slug === PrePurchaseConstants.noSlug) {
            ctaAttribs.disabled = true;
        }
        return <div className={'pre-purchase-product-comparison__carousel-main'}>
            <div className='pre-purchase-product-comparison__best-match-label'>
                <TitleInfoIcon titleElement={bestMatch} infoIcon={infoIcon} toolTip={prePurchaseBestMatchProductTip} arrowIcon={toolTipArrowIcon} />
            </div>
            {productImage && <Image className="pre-purchase-product-comparison__product-image" image={productImage} />}
            <h2 tabIndex={0} className='pre-purchase-product-comparison__product-name' dangerouslySetInnerHTML={{ __html: prePurchaseProductName }} />
            <p className='pre-purchase-product-comparison__product-description'>{prePurchaseProductDescription}</p>
            <div itemScope itemType={PrePurchaseConstants.itemType}>
                <meta itemProp={product?.fields?.name} content={PrePurchaseConstants.ProductName} name={product?.fields?.name} />
                <div data-bv-show={PrePurchaseConstants.inlineRating} data-bv-product-id={reviewAndRatingId} />
            </div>
            <div className='pre-purchase-product-comparison__price-starts-at'>
                <span>{priceStartsAt && `${startingAtPriceLabel} ${priceStartsAt}`}</span>
            </div>
            <div className='pre-purchase-product-comparison__color-label-text'>
                <span>{colorsLabel}</span>
            </div>
            <ul className="pre-purchase-product-comparison__product-colors-list">
                {productVariants.map((variant, variantIndex) => {
                    const color = variant?.fields?.color;
                    if (color) {
                        return (
                            <li className="pre-purchase-product-comparison__product-colors-item" key={variantIndex}>
                                <button aria-label={color.fields.name}
                                    type={PrePurchaseConstants.button}
                                    className={C('ob-series__color event_buy_now_choose_product', {
                                        ['ob-series__color--active']: variantIndex === selectedVariantsIndex[productIndex]
                                    })}
                                    style={getColorStyle(color)}
                                    data-action-detail={`${color?.fields?.name}_${stripHtml(prePurchaseProductName)}`}
                                    onClick={() => { selectedVariantsIndex[productIndex] = variantIndex; setSelectedVariants([...selectedVariantsIndex]) }}
                                    aria-pressed={variantIndex === selectedVariantsIndex[productIndex] ? true : false}
                                >
                                    {color.fields.name}
                                </button>
                            </li>
                        )
                    }
                })}
            </ul>
            {product?.fields?.comingSoonLink ? <ObLink
                href={product?.fields?.comingSoonLink}
                isExternalLink={true}
                className={'ob-button pre-purchase-compare-chart__learn-more-cta event_external_link'}
                dataActionDetail={`pre_purchase_${eventLabel}`} >
                <span>{learnMoreCtaText}</span>
            </ObLink> :
                <button
                    className={'ob-button pre-purchase-product-comparison__learn-more-cta event_internal_link'}
                    onClick={() => openTo(product?.fields?.slug?.fields?.slug, pdpTarget)}
                    data-action-detail={`pre_purchase_${eventLabel}`}
                    {...ctaAttribs}
                >
                    <span>{learnMoreCtaText}</span>
                </button>
            }
        </div>
    }
    const isValidProducts = isMobileView && Array.isArray(selectedProducts) && selectedProducts.length > 0;
    const displayCarousel = numPages > 1;
    const hideLeftImage = currentPage === 0;
    const hideRightImage = currentPage == numPages - 1;
    const leftImage = hideLeftImage ? null : (selectedProducts[leftPageIndex]?.fields?.productVariants[selectedVariantsIndex[leftPageIndex]]?.fields?.mainAsset || selectedProducts[leftPageIndex]?.fields?.mainAsset);
    const rightImage = hideRightImage ? null : (selectedProducts[rightPageIndex]?.fields?.productVariants[selectedVariantsIndex[rightPageIndex]]?.fields?.mainAsset || selectedProducts[rightPageIndex]?.fields?.mainAsset);
    const isCrossWrapper = isMobileView && iOS;
    const isValidJebbitData = Array.isArray(jebbitData) && jebbitData.length > 0;
    if (!isValidJebbitData) {
        return <section className='pre-purchase-comparison-no-data' />;
    }
    return (<section className='pre-purchase-comparison' id={PrePurchaseConstants.sectionId}>
        <div className={`pre-purchase-product-comparison__wrapper ${isCrossWrapper ? 'pre-purchase-product-comparison__cross-wrapper' : ''}`}>
            <Heading tabIndex={0} className={'pre-purchase-product-comparison__title'} tag={`h${compareResultTitleLevel}`}>{title}</Heading>
            {isMobileView ?
                <div className={'pre-purchase-product-comparison__carousel'}>
                    {isValidProducts && <div className={'pre-purchase-product-comparison__carousel-inner'} >
                        <div className='pre-purchase-product-comparison__carousel-left-image'>
                            {displayCarousel && leftImage && <Image image={leftImage} />}
                        </div>
                        {displayProductMobileView()}
                        <div className='pre-purchase-product-comparison__carousel-right-image'>
                            {displayCarousel && rightImage && <Image image={rightImage} />}
                        </div>
                        <div className='pre-purchase-product-comparison__pre-load-images'>
                            {
                                selectedProducts?.map((product, productIndex) => {
                                    const image = product.fields?.mainAsset;
                                    image.fields.isPreload = true;
                                    return <Image image={image} key={productIndex} />
                                })
                            }
                        </div>
                    </div>}

                    {displayCarousel &&
                        <div className='pre-purchase-product-comparison__carousel-control'>
                            <button className={'pre-purchase-product-comparison__carousel--control-button'}
                                data-control-button={'prev'}
                                aria-label={navLeftIcon?.fields?.alternateText}
                                onClick={handleClick}
                                disabled={currentPage === 0}
                                type={'button'}>
                                <span>
                                    <Image image={navLeftIcon} />
                                </span>
                            </button>
                            <button className={'pre-purchase-product-comparison__carousel--control-button'}
                                data-control-button={'next'}
                                aria-label={navRightIcon?.fields?.alternateText}
                                onClick={handleClick}
                                disabled={currentPage === numPages - 1}
                                type={'button'}>
                                <span>
                                    <Image image={navRightIcon} />
                                </span>
                            </button>
                        </div>
                    }
                </div>
                : <div className='pre-purchase-product-comparison__table' role={PrePurchaseConstants.table}>
                    <Row className='pre-purchase-compare-chart__row pre-purchase-product-comparison__row'>
                        {
                            selectedProducts.map((product, productIndex) => {
                                const reviewAndRatingId = getReviewAndRatingId(product);
                                const { productVariants, prePurchaseProductName, prePurchaseProductDescription, prePurchaseStartingAtPrice, prePurchaseBestMatchProductTip } = product?.fields;
                                const productVariant = productVariants[selectedVariantsIndex[productIndex]];
                                const productImage = productVariant?.fields?.mainAsset || product?.fields?.mainAsset;
                                const priceStartsAt = prePurchaseStartingAtPrice && prePurchaseStartingAtPrice;
                                const bestMatch = matchedResults[productIndex];
                                const eventLabel = prePurchaseProductName.replace(/\s/g, "")
                                return <Cell key={productIndex} className='pre-purchase-product-comparison__column'>
                                    {productImage && <Image className="pre-purchase-product-comparison__product-image" image={productImage} />}
                                    <div tabIndex={0} className='pre-purchase-product-comparison__best-match-label'>
                                        <TitleInfoIcon titleElement={bestMatch} infoIcon={infoIcon} toolTip={prePurchaseBestMatchProductTip} arrowIcon={toolTipArrowIcon} />
                                    </div>
                                    <h2 tabIndex={0} className='pre-purchase-product-comparison__product-name' dangerouslySetInnerHTML={{ __html: prePurchaseProductName }} />
                                    <p tabIndex={0} className='pre-purchase-product-comparison__product-description'>{prePurchaseProductDescription}</p>
                                    <div tabIndex={0} itemScope itemType={PrePurchaseConstants.itemType}>
                                        <meta itemProp={product?.fields?.name} content={PrePurchaseConstants.ProductName} name={product?.fields?.name} />
                                        <div data-bv-show={PrePurchaseConstants.inlineRating} data-bv-product-id={reviewAndRatingId} />
                                    </div>
                                    <div className='pre-purchase-product-comparison__price-starts-at'>
                                        <span>{priceStartsAt && `${startingAtPriceLabel} ${priceStartsAt}`}</span>
                                    </div>
                                    <div className='pre-purchase-product-comparison__color-label-text'>
                                        <span>{colorsLabel}</span>
                                    </div>
                                    <ul className="pre-purchase-product-comparison__product-colors-list">
                                        {productVariants.map((variant, variantIndex) => {
                                            const color = variant?.fields?.color;
                                            if (color) {
                                                return (
                                                    <li className="pre-purchase-product-comparison__product-colors-item" key={variantIndex}>
                                                        <button aria-label={color.fields.name}
                                                            type={PrePurchaseConstants.button}
                                                            className={C('ob-series__color ob-series__color--large-mobile  event_buy_now_choose_product', {
                                                                ['ob-series__color--active']: variantIndex === selectedVariantsIndex[productIndex]
                                                            })}
                                                            style={getColorStyle(color)}
                                                            data-action-detail={`${color?.fields?.name}_${stripHtml(prePurchaseProductName)}`}
                                                            onClick={() => { selectedVariantsIndex[productIndex] = variantIndex; setSelectedVariants([...selectedVariantsIndex]) }}
                                                            aria-pressed={variantIndex === selectedVariantsIndex[productIndex] ? true : false}
                                                        >
                                                            {color.fields.name}
                                                        </button>
                                                    </li>
                                                )
                                            }
                                        })}
                                    </ul>
                                    {
                                        product?.fields?.comingSoonLink ? <ObLink
                                            href={product?.fields?.comingSoonLink}
                                            isExternalLink={true}
                                            className={'ob-button pre-purchase-compare-chart__learn-more-cta event_external_link'}
                                            dataActionDetail={`pre_purchase_${eventLabel}`} >
                                            <span>{learnMoreCtaText}</span>
                                        </ObLink> :
                                            <button
                                                className={'ob-button pre-purchase-compare-chart__learn-more-cta event_internal_link'}
                                                onClick={() => openTo(product?.fields?.slug?.fields?.slug, pdpTarget)}
                                                data-action-detail={`pre_purchase_${eventLabel}`}
                                            >
                                                <span>{learnMoreCtaText}</span>
                                            </button>
                                    }
                                </Cell>
                            })
                        }
                    </Row>
                </div>
            }
        </div>
        <div className={`pre-purchase-compare-chart  ${isCrossWrapper ? 'pre-purchase-compare-chart-cross-wrapper' : ''}`}>
            <Heading tabIndex={0} className={'pre-purchase-compare-chart__title'} tag={`h${compareChartTitleLevel}`}>{compareChartTitle}</Heading>
            {isMobileView ? comparisonMobileView(isCrossWrapper) : comparisonDesktopView()}
        </div>
        <Modal
            isOpen={isMobileView && isModalOpen}
            onRequestClose={closeModal}
            closeTimeoutMS={closeModalTimeoutMS}
            style={customStyles}
            htmlOpenClassName={'ob-brush-select-list__modal-overlay'}
            className={{
                afterOpen: 'ob-brush-select-list__after-open'
            }}
        >
            <div className={'ob-brush-select-list__modal-container'}>
                <div className='ob-brush-select-list__brush-list-wrapper'>
                    {!isEmptyBrushList() && <List items={brushList}
                        onChange={updateBrushIndex}
                        selected={[selectedBrushIndex[compareModalIndex]]}
                        keyboardEvents={false}
                    />}
                </div>
            </div>
        </Modal>
    </section>);
}

PrePurchaseResult.propTypes = {
    document: PropTypes.object,
    onClickCallback: PropTypes.func,
    isMedium: PropTypes.bool,
};


export default mediaQueryHOC(PrePurchaseResult);
// This export is for unit testing (do not remove) :
export const PrePurchaseResultTest = PrePurchaseResult;