import BodyText from '../BodyText/BodyText';
import Button from '../Button/Button';
import Disclaimer from '../Disclaimer/Disclaimer';
import Heading from '../Heading/Heading';
import Image from '../Image/Image';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Block from '../../adapters/helpers/Block';
import ClicRegister from '../ClicRegister/ClicRegister'
import { ContentConstants, Constants } from '../../adapters/helpers/Constants';
import { ArticleCategoryHeroBlockConstants } from '../../adapters/helpers/Constants';
import Icon from '../Icon/Icon';

class ContentDetailsBlock extends Component {
    constructor(props) {
        super(props);
        this.block = new Block(props);
        const cmsClassNames = this.block.getFieldValue('classNames')
        this.classNames = 'ob-content-details-block';
        if (cmsClassNames) {
            this.classNames += ' ' + cmsClassNames;
        }

        this.anchorId = this.block.getAnchorId();
        this.title = this.block.getFieldValue('title');
        this.description = this.block.getFieldValue('description');
        this.detailsTitle = this.block.getFieldValue('detailsTitle');
        this.disclaimer = this.block.getFieldValue('disclaimer');
        this.details = this.block.getFieldValue('details');
        this.ctaLabel = this.block.getFieldValue('ctaLabel') ?
            this.block.getFieldValue('ctaLabel').fields.text : undefined;
        this.ctaLink = this.block.getFieldValue('ctaLink') ?
            this.block.getFieldValue('ctaLink').fields.url : undefined;
        this.backgroundAsset = this.block.getFieldValue('backgroundAsset');
        this.mobileBackgroundAsset = this.block.getFieldValue('mobileBackgroundAsset');
        this.isClicPage = (cmsClassNames || '')?.includes(ContentConstants.clicFavoriteOptionClass);
        this.registerPopup = this.block.getFieldValue(Constants.registerPopup);
        this.state = {
            isRegisterModelOpen: false
        }
    }

    handleRegisterOpen = () => {
        this.setState({isRegisterModelOpen: true})
    }

    handleRegisterClose = () => {
        this.setState({isRegisterModelOpen: false})
    }

    render() {
        const { languageCode } = this.props
        return (
            <>
            <section className={this.classNames} id={this.anchorId}>
                <div className={'ob-content-details-block-wrapper'}>
                    <div className={'ob-content-details-block__content'}>
                        <div className={'ob-content-details-block__image-content'}>
                            {this.details &&
                                <div className={'ob-content-details-block__image-content__details'}>
                                    {this.detailsTitle &&
                                        <div className={'ob-content-details-block__details-title'}>
                                            {this.detailsTitle}
                                        </div>
                                    }
                                    {this.details.map((detail, index) => (
                                        <div
                                            className={'ob-content-details-block__detail-content'}
                                            key={index}
                                            data-index={index}>
                                            {detail?.fields?.title && <div className={'ob-content-details-block__detail-title'} dangerouslySetInnerHTML={{__html: detail?.fields?.title}}></div>}
                                            {detail?.fields?.logo &&
                                                <div className={'ob-content-details-block__detail-content-image'}>
                                                    <Image image={detail?.fields?.logo} noAltText='true' /> 
                                                </div>
                                            }
                                            <div>
                                                <div className={'ob-content-details-block__detail-content-text'}  dangerouslySetInnerHTML={{__html: detail?.fields?.description}}/>
                                                {detail?.fields?.ctAs && detail?.fields?.ctAs.map((cta, ctaIndex) => (
                                                    <span className='ob-article-category-hero-cta-item' key={ctaIndex}>
                                                        <a className='ob-article-category-hero-cta-link ob-link primaryGrey'
                                                            href={cta.fields.url}>
                                                            <Icon className='ob-article-category-hero-cta-icon'
                                                                name={ArticleCategoryHeroBlockConstants.chevronRight}
                                                                roundedIcon={ArticleCategoryHeroBlockConstants.blue} size={1.4} />

                                                            {cta.fields.title}
                                                        </a>
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                        ))
                                    }
                                    

                                </div>
                            }
                            {this.isClicPage &&
                                <div className='ob-content-details-block__text-content__obLink-container'>
                                    <Button whiteTheme={false}
                                        tag='button'
                                        className={'event_external_link'}
                                        dataActionDetail={this.ctaLink}
                                        onClick={this.handleRegisterOpen.bind(this)}
                                    >
                                        {this.ctaLabel}
                                    </Button>
                                </div>
                            }
                            <div className={'ob-content-details-block__image-content__main-image'}>
                                {this.backgroundAsset &&
                                    <div className={'ob-content-details-block__image-content__main-image-desktop'}>
                                        <Image image={this.backgroundAsset} />
                                    </div>
                                }
                                {this.mobileBackgroundAsset &&
                                    <div className={'ob-content-details-block__image-content__main-image-mobile'}>
                                        <Image image={this.mobileBackgroundAsset} />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={'ob-content-details-block__text-content'}>
                            <div className={'ob-content-details-block__text'}>
                                {this.title &&
                                    <Heading
                                        className={'ob-display-2-xl'}
                                        whiteText={false}
                                        tag={'h2'}>
                                        {this.title}
                                    </Heading>
                                }
                                {this.description &&
                                    <BodyText
                                        whiteText={false}>
                                        {this.description}
                                    </BodyText>
                                }
                                
                                {!this.isClicPage && this.ctaLabel && this.ctaLink &&
                                    <div className='ob-content-details-block__text-content__obLink-container'>
                                        <Button whiteTheme={false}
                                            tag='a'
                                            className={'event_external_link'}
                                            dataActionDetail={this.ctaLink}
                                            href={this.ctaLink}>
                                            {this.ctaLabel}
                                        </Button>
                                    </div>
                                }
                                
                                {this.disclaimer &&
                                    <Disclaimer whiteText={false}>{this.disclaimer}</Disclaimer>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ClicRegister handleClose={this.handleRegisterClose.bind(this)} 
            isModelOpen={this.state.isRegisterModelOpen} languageCode={languageCode}
            registerPopup={this.registerPopup} />
            </>
        );
    }
}

ContentDetailsBlock.propTypes = {
    extraAttributes: PropTypes.object,
    languageCode: PropTypes.string
};

export default ContentDetailsBlock;
