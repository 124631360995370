import {getAnchorIdFromEntity} from '../../adapters/helpers/Anchor';
import {SeriesTabsConstants} from '../../adapters/helpers/Constants';
import C from 'classnames';
import Icon from '../Icon/Icon';
import PropTypes from 'prop-types';
import React, {useCallback, useRef, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import {stripHtml} from '../../adapters/helpers/Utils';
import {Constants} from '../../adapters/helpers/Constants';
export default function SeriesTabs({ items, selectedItem, selectedIndex, basePath }) {
    const [navCollapsed, setNavCollapsed] = useState(true);
    const [navHeight, setNavHeight] = useState(0);
    const navList = useRef(null);
    const handleNavToggle = useCallback(
        () => { setNavCollapsed(prevState => !prevState); },
        [],
    );

    const calculateHeight = useCallback(
        () => {
            if (navList.current) {
                setNavHeight(navList.current.scrollHeight);
            }
        },
        [],
    );
    let basePathSlash = basePath.replace(/\/$/, "");

    return (items && items.length > 0) ? (
        <CSSTransition in={!navCollapsed} timeout={800} onEntering={calculateHeight} onExit={calculateHeight}>
            <nav className='ob-series-selector__nav'>
                <div className='ob-series-selector__nav-toggle' role={SeriesTabsConstants.buttonRole} tabIndex='0' onKeyDown={handleNavToggle} onKeyPress={handleNavToggle} aria-expanded={!navCollapsed}  aria-hidden={false} onClick={handleNavToggle}>
                    <div className='ob-series-selector__nav-link-name'
                        dangerouslySetInnerHTML={{__html: selectedItem.fields.shortTitle}}>
                    </div>

                    {!!selectedItem.fields.startingAtLabel && (
                        <div className='ob-series-selector__nav-link-price'>
                            {selectedItem.fields.startingAtLabel.fields.text}
                        </div>
                    )}

                    <Icon className='ob-series-selector__nav-chevron' name={SeriesTabsConstants.chevronDown} size={1.6} />
                </div>

                <div className='ob-series-selector__nav-collapsible' style={{ height: navHeight }}>
                    <ul className='ob-series-selector__nav-list' ref={navList}>
                        {items.map((item, i) => (
                            <li key={i}
                                className={C('ob-series-selector__nav-item', {
                                    ['ob-series-selector__nav-item--active']: i === selectedIndex
                                })}
                                aria-hidden={false}
                                
                            >
                                    <a className='ob-series-selector__nav-link event_button_click'
                                        data-action-detail={stripHtml(item.fields.shortTitle)}
                                        href={`${basePathSlash}${Constants.pathDelimiter}${getAnchorIdFromEntity(item)}`}
                                    >
                                        <div>
                                            {
                                                i == selectedIndex ? 
                                                <div className='ob-series-selector__nav-link-name'
                                                    aria-current={SeriesTabsConstants.page}
                                                    dangerouslySetInnerHTML={{__html: item.fields.shortTitle }}>
                                                </div>
                                            :
                                                <div className='ob-series-selector__nav-link-name'
                                                    dangerouslySetInnerHTML={{__html: item.fields.shortTitle }}>
                                                </div>
                                            }

                                            {!!item.fields.startingAtLabel && (
                                                <div className='ob-series-selector__nav-link-price'>
                                                    {item.fields.startingAtLabel.fields.text}
                                                </div>
                                            )}
                                        </div>
                                    </a>
                            </li>
                        )
                        
                        )}
                    </ul>
                </div>
            </nav>
        </CSSTransition>
    ) : null;
}

SeriesTabs.propTypes = {
    items: PropTypes.any,
    selectedItem: PropTypes.any,
    selectedIndex: PropTypes.any,
    basePath: PropTypes.any
}
