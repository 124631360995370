export const AnalyticsConstants = Object.freeze({
    collapseText: 'Collapse Link',
    expandText: 'Expand Link',
    learnMoreText: 'Learn More',
    countrySelectorText: 'Open Country Selector Panel',
    openVideoText: 'Open Video',
    buttonPriceText: 'Open Price Panel',
    contentVideoOverlayText: 'Outside Panel',
    videoPlayText: 'Play',
    videoPauseText: 'Pause',
    videoMuteText: 'Sound: Off',
    videoUnmuteText: 'Sound: On',
    videoCloseCaptionsOnText: 'Caption: On',
    videoCloseCaptionsOffText: 'Caption: Off',
    videoFullscreenOnText: 'Full Screen: On',
    videoFullscreenOffText: 'Full Screen: Off',
    dropdownMenu: 'Dropdown Menu',
    rotationView: '360 View',
    facebook: 'Facebook',
    twitter: 'Twitter',
    openLiveChat: 'Open Live Chat',
    expandFaq: 'Expand FAQ',
    previousPage: 'Previous Page',
    nextPage: 'Next Page',
    promoRedeem: 'Redeem: ',
    calender: 'Calender',
    openMenu: 'Open Menu',
    closeMenu: 'Close Menu',
    signUpEvent: 'sign up intent',
    buttonClickEventAction: 'button_click',
    buttonClickEventCategory:'event_informational_action'
});