import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import Label from '../../../Label/Label';
import ToggleInput from '../ToggleInput/ToggleInput';
import Eyebrow from '../../../Eyebrow/Eyebrow';
import FormGoalsCheckboxes from '../FormGoalsCheckboxes/FormGoalsCheckboxes';
import {inputs} from '../../Janrain/janrainForms/ProfileForm/inputs';
import Button from '../../../Button/Button';
import {SubmitButton} from '../index';
import {isObjectEmpty, getDateInNinetyDays, substractDays} from '../../../../adapters/helpers/Utils';
import {getPostReplaceEntityObject, postReplaceBenefitPreferences} from '../../../../adapters/model/service/api/svc';
import { mediaQueryHOC } from '../../../../adapters/helpers/Hooks';
import {isToday, recoverPreferencesFromUserData} from '../../helpers/setting.helpers';
import {getJanrainCaptureProfileDataFromLocalStorage} from '../../helpers/localstorage.helpers';
import PropTypes from 'prop-types';

const UpdateBenefitPreferences = ({profileBenefitPreferencesTab, commonLabels, userData, customStyles, isMedium, closeModalTimeoutMS}) => {

    const [oralbBenefitPreferenceList, setOralbBenefitPreferenceList] = useState([]);
    const [goalsJson, setGoalsJson] = useState({});
    const [isReminded, setIsReminded] = useState(userData?.traitBrushHeadReminderOptInFlag?.traitValue === 'Yes');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [areChangesSaved, setAreChangesSaved] = useState(false);
    const [isFormProcessing, setFormProcessing] = useState(false);

    let traitBrushheadReminderDate = new Date();
    if(userData.traitBrushheadReminderDate?.traitDate) {
        let splitDate = userData.traitBrushheadReminderDate?.traitDate.split('-')
        traitBrushheadReminderDate = new Date(splitDate[0], splitDate[1] - 1, splitDate[2]);
    }
    const [brushheadReminderDateValue, setBrushheadReminderDateValue] = useState(traitBrushheadReminderDate);

    const locale = process.env.JANRAIN_CAPTURE_LOCALE;
    const threeMonthsFromNow = getDateInNinetyDays();

    const brushHeadReminderDates = profileBenefitPreferencesTab?.brushHeadAgeList;

    const [currentReminderDate, setCurrentReminderDate] = useState(getDateInNinetyDays(traitBrushheadReminderDate) || threeMonthsFromNow);

    const [daysSincePurchase, setDaysSincePurchase] = useState(null);

    const janrainLabels = {
        yourPreferenceTitle: profileBenefitPreferencesTab?.yourPreferenceTitle,
        yourPreferenceText: profileBenefitPreferencesTab?.yourPreferenceText,
        replaceBrushHeadTitle: profileBenefitPreferencesTab?.replaceBrushHeadTitle,
        replaceBrushHeadText: profileBenefitPreferencesTab?.replaceBrushHeadText,
        resetReminder: profileBenefitPreferencesTab?.resetReminder?.fields?.text,
        myBenefitPreferencesTitle: profileBenefitPreferencesTab?.myBenefitPreferencesTitle,
        myBenefitPreferencesSubtitle: profileBenefitPreferencesTab?.myBenefitPreferencesSubtitle,
        onLabel: profileBenefitPreferencesTab?.onLabel?.fields?.text,
        offLabel: profileBenefitPreferencesTab?.offLabel?.fields?.text,
        ...commonLabels
    };

    const resetReminder = (numDays) => {
        const daysSince = parseInt(numDays, 10);
        const newReminderDate = getDateInNinetyDays();
        setDaysSincePurchase(daysSince);
        newReminderDate.setDate(newReminderDate.getDate() - daysSince);
        setCurrentReminderDate(newReminderDate);

        // the value save in janrain is the date of purchase. They are adding the 90 days themselves for the reminder.
        // So we need to save the date of purchase and show date of purchase + 90 days.
        let reminderDateValue = substractDays(new Date(), daysSince);

        setBrushheadReminderDateValue(reminderDateValue);
    };

    const setGoalsData = data => {
        setGoalsJson(data);
    };

    useEffect(() => {
        assignCurrentBenefitPreferencesFromUserData();

        if (document.querySelector('.js-modal-container')) {
            Modal.setAppElement('.js-modal-container');
        }
    },[]);

    useEffect(() => {
        if(isFormProcessing) {
            window.janrain.events.onCaptureSaveSuccess.addHandler(handleFormSendSuccess);
            window.janrain.events.onCaptureSaveFailed.addHandler(handleFormSendFailed);
        }
    }, [isFormProcessing]);

    useEffect(() => {
        //reset reminder when turning reminder to true
        if(isToday(traitBrushheadReminderDate)) {
            resetReminder(0);
        }
    }, [isReminded]);

    const handleFormSendFailed = () => {
        setFormProcessing(false);
        setAreChangesSaved(false);
    };

    const handleFormSendSuccess = () => {
        setFormProcessing(false);
        setAreChangesSaved(true);
    };

    const assignCurrentBenefitPreferencesFromUserData = () => {
        let benefitList = recoverPreferencesFromUserData(userData);
        setOralbBenefitPreferenceList(benefitList);
        setGoalsData(benefitList);
    };

    const handleSubmit = () => {
        setFormProcessing(true);
        const janrainProfileData = JSON.parse(getJanrainCaptureProfileDataFromLocalStorage());
        let postObject = getPostReplaceEntityObject(janrainProfileData);
        // reminder Date
        const reminderValue =  (isReminded) ? 'Yes' : null;
        let traitBrushHeadReminderOptInFlagValue = {
            traitValue: reminderValue
        }
        postObject['traitBrushHeadReminderOptInFlag'] = traitBrushHeadReminderOptInFlagValue;
        let reminderDate = brushheadReminderDateValue.toISOString().split('T')[0];
        if(!isReminded) {
            reminderDate = null;
        }
        let traitBrushheadReminderDateValue = {
            traitDate: reminderDate
        }
        postObject['traitBrushheadReminderDate'] = traitBrushheadReminderDateValue;
        if (!isObjectEmpty(goalsJson)) {
            // BenefitPreferences
            let traitBenefitPreferencesValue = [];
            postObject['traitBenefitPreferences'] = [];
            goalsJson.forEach(goal => {
                let goalObject = {
                    traitValue: goal,
                    traitLastUpdated: new Date(),
                    traitLastUpdatedClientId: process.env.JANRAIN_CAPTURE_CIENT_ID
                }
                traitBenefitPreferencesValue.push(goalObject);
            });
            postObject['traitBenefitPreferences'] = traitBenefitPreferencesValue;
        } else {
            postObject['traitBenefitPreferences'] = [];
        }
        postReplaceBenefitPreferences(postObject, handleFormSendSuccess, handleFormSendFailed);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openModal = event => {
        event.preventDefault();
        setAreChangesSaved(false);
        setIsModalOpen(true);
    };

    return (
        <div className={'ob-preferences js-modal-container'}>
            <div className={'ob-preferences__wrapper ob-preferences__wrapper--with-border'}>
                <Eyebrow>{janrainLabels.yourPreferenceTitle}</Eyebrow>
                <p className={'ob-preferences__text'}>{janrainLabels.yourPreferenceText}</p>
            </div>
            <div className={'ob-preferences__wrapper ob-preferences__wrapper--with-border'}>
                <div className={'ob-preferences__toggle-container'}>
                    <Eyebrow className={'ob-preferences__toggle-title'}>{janrainLabels.replaceBrushHeadTitle}</Eyebrow>
                    {!isMedium &&
                        <ToggleInput
                            value={isReminded}
                            onChange={(e) => setIsReminded(e.target.checked)}
                            checked={isReminded}
                            name={inputs.field.traitBrushHeadReminderOptInFlag}
                            activeLabel={janrainLabels.onLabel}
                            inactiveLabel={janrainLabels.offLabel}
                            className={'ob-preferences__toggle-input--desktop'}
                        />
                    }
                </div>
                <p className={'ob-preferences__text'}>
                    {profileBenefitPreferencesTab?.RemindYouToReplace?.fields?.text}
                </p>
                {locale !== '' && isReminded &&
                    <Eyebrow className={'ob-preferences__remind-date'}>
                        <Label
                            tokens={{replacementDate: currentReminderDate.toLocaleString(locale, { month: 'long', day: 'numeric'}).toUpperCase()}}
                            label={janrainLabels.replaceBrushHeadText} />
                    </Eyebrow>
                }
                <p className={'ob-preferences__text'}>{janrainLabels.resetReminder}</p>
                {isMedium &&
                    <ToggleInput
                        value={isReminded}
                        onChange={(e) => setIsReminded(e.target.checked)}
                        checked={isReminded}
                        name={inputs.field.traitBrushHeadReminderOptInFlag}
                        activeLabel={janrainLabels.onLabel}
                        inactiveLabel={janrainLabels.offLabel}
                        className={'ob-preferences__toggle-input--mobile'}
                    />
                }
                {isReminded &&
                    <ul className={'ob-preferences__reminder-list'}>
                        {brushHeadReminderDates.map((date, index) => (
                            <li key={index} className={'ob-preferences__reminder-list-item'}>
                                <input
                                    className={'ob-preferences__reminder-radio'}
                                    type={'radio'}
                                    name={'dateReminder'}
                                    id={`${date?.fields?.value}-dateReminder`}
                                    value={date?.fields?.value}
                                    checked={date?.fields?.value === daysSincePurchase}
                                    onChange={() => resetReminder(date?.fields?.value)}
                                />
                                <label
                                    className={'ob-preferences__reminder-label'}
                                    htmlFor={`${date?.fields?.value}-dateReminder`}
                                >
                                    {date?.fields?.radioLabel?.fields?.text}
                                </label>
                            </li>
                        ))}
                    </ul>
                }
            </div>

            <div className={'ob-preferences__wrapper'}>
                <p className={'ob-preferences__text ob-preferences__goals-title'}>{janrainLabels.myBenefitPreferencesTitle}</p>
                <Eyebrow>{janrainLabels.myBenefitPreferencesSubtitle}</Eyebrow>
                <FormGoalsCheckboxes
                    janrainLabels={janrainLabels}
                    oralCareGoals={profileBenefitPreferencesTab.topOralCareGoals}
                    currentGoals={oralbBenefitPreferenceList}
                    setGoalsData={setGoalsData} />
            </div>

            <Button
                type={'button'}
                onClick={openModal}
                size={'large-slim'}
                className={'ob-profile__full-width-btn ob-edit-profile__save-btn'}
            >
                {janrainLabels.saveLabel}
            </Button>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                closeTimeoutMS={closeModalTimeoutMS}
                style={customStyles}
                htmlOpenClassName={'ob-profile__modal-overlay'}
            >
                <div className={'ob-profile__modal-container ob-edit-profile__modal-container'}>
                    <div>
                        <p className={'ob-profile__modal-title'}>
                            {janrainLabels.editModalSaveChangesTitle}
                        </p>
                    </div>
                    <div>
                        {!areChangesSaved ? (
                            <>
                                <SubmitButton
                                    buttonText={janrainLabels.saveChangesLabel}
                                    isSubmitting={isFormProcessing}
                                    onClick={handleSubmit}
                                />
                                <Button textTheme={true}
                                        className={'ob-profile__modal-cancel-btn'}
                                        onClick={closeModal}>
                                    {janrainLabels.modalCancelButton}
                                </Button>
                            </>
                        ) : (
                            <>
                                <p className={'ob-edit-profile__success'}>{janrainLabels.editModalSuccess}</p>
                                <Button textTheme={true}
                                        className={'ob-profile__modal-cancel-btn'}
                                        onClick={closeModal}>
                                    {janrainLabels.closeModal}
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    )
};

UpdateBenefitPreferences.propTypes = {
    profileBenefitPreferencesTab: PropTypes.object,
    commonLabels: PropTypes.object,
    userData: PropTypes.object,
    customStyles: PropTypes.string,
    isMedium: PropTypes.bool,
    closeModalTimeoutMS: PropTypes.number
};

export default mediaQueryHOC(UpdateBenefitPreferences);
// This export is for unit testing (do not remove) :
export const UpdateBenefitPreferencesTest = UpdateBenefitPreferences;
