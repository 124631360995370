import React, { useEffect, useRef } from 'react';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, FreeMode } from 'swiper';
import 'swiper/css/bundle';
import Block from '../../adapters/helpers/Block';

const ArticleCarouselBlock = (props) => {
  const { document: doc, isSmall } = props;

  const className = doc?.fields?.className;
  const relatedBlocks = doc?.fields?.relatedBlocks;
  const leftArrow = doc?.fields?.leftArrowImage;
  const rightArrow = doc?.fields?.rightArrowImage;

  const swiperRef = useRef(null);

  useEffect(() => {
    const nextButton = document.querySelector('.swiper-button-next');
    const prevButton = document.querySelector('.swiper-button-prev');

    if (nextButton) {
      nextButton.style.background = `url('https:${rightArrow?.fields?.file?.url}')`;
    }

    if (prevButton) {
      prevButton.style.background = `url('https:${leftArrow?.fields?.file?.url}')`;
    }
  }, [rightArrow, leftArrow]);

  const renderSwiperItems = () => {
    return (
      <>
        {relatedBlocks &&
          relatedBlocks.map((item, index) => {
            return (
              <SwiperSlide>
                {new Block(item).renderBlockFromDocument(item)}{' '}
              </SwiperSlide>
            );
          })}
      </>
    );
  };

  const renderDesktopContent = () => {
    return (
      <>
        {relatedBlocks.length > 3 ? (
          <section
            className={`article-carousel-block ${className ? className : ''}`}
          >
            <Swiper
              ref={swiperRef}
              modules={[Navigation, FreeMode]}
              slidesPerView={3.5}
              spaceBetween={20}
              navigation={true}
              freeMode={{
                enabled: 'true',
              }}
            >
              {renderSwiperItems()}
            </Swiper>
          </section>
        ) : (
          <section
            className={`article-default-block ${className ? className : ''}`}
          >
            {relatedBlocks.map((item, index) => {
              return (
                <div>{new Block(item).renderBlockFromDocument(item)} </div>
              );
            })}
          </section>
        )}
      </>
    );
  };

  const renderMobileContent = () => {
    return (
      <section
        className={`article-carousel-block ${className ? className : ''}`}
      >
        <Swiper
          ref={swiperRef}
          slidesPerView={1.4}
          loop
          centeredSlides
          spaceBetween={20}
        >
          {renderSwiperItems()}
        </Swiper>
      </section>
    );
  };

  return (
    <>
      {isSmall && renderMobileContent()}
      {!isSmall && renderDesktopContent()}
    </>
  );
};

export default mediaQueryHOC(ArticleCarouselBlock);
