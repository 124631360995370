import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {CookiesNotificationOverlayConstants} from '../../adapters/helpers/Constants';
import Modal from 'react-modal';

function CookiesNotificationOverlay(props) {

    const modalDelay = props.document?.fields?.modalDelay ? props.document?.fields?.modalDelay : 1;
    const notificationOverlayContent = props.document?.fields?.notificationOverlayContent;
    const className = props.document?.fields?.classNames;
    const notificationOverlayEnabled = props.document?.fields?.notificationOverlayEnabled;
    const localStorageVariable =  CookiesNotificationOverlayConstants.cookiesOverlayTriggered;
    const ctaLabel = props.document?.fields?.ctaLabel;

    const customModalStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 200
        },
        content : {
            background          : 'none',
            border              : 'none',
            borderRadius        : '0',
            top                 : '50%',
            left                : '50%',
            right               : 'auto',
            bottom              : 'auto',
            fontSize            : '1.4rem',
            lineHeight          : '2.1rem',
            marginRight         : '-50%',
            padding             : '0',
            maxWidth            : '700px',
            transform           : 'translate(-50%, -50%)',
            width               : '100%'
        }
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        document.onreadystatechange = function() {
            if (document.readyState === "complete") { 
                if(notificationOverlayContent && notificationOverlayEnabled && !Boolean(localStorage.getItem(localStorageVariable))) {
                    //setTimeout(() => {
                        setModalIsOpen(true);            
                        localStorage.setItem(localStorageVariable, true);                        
                    //}, (parseInt(modalDelay)*1000));          
                } else {
                    setModalIsOpen(false);
                }
            }
    }
    }, []);

    const closeModal = () => {
        setModalIsOpen(false);        
    };
    
    return (
        <div className="ob-cookiesoverlay">
        { modalIsOpen && <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    closeTimeoutMS={250}
                    style={customModalStyles}
                    shouldCloseOnOverlayClick={false}
                    aria={{
                        label : CookiesNotificationOverlayConstants.cookiesOverlayDialog}}
                    >
                    <div className='ob-cookiesoverlay-modal'>
                    <div id="close_cookiesoverlay" role='button' tabIndex={0} className="ob-cookiesoverlay-close event_button_click" aria-label={CookiesNotificationOverlayConstants.cookiesOverlayClose}  onClick={() => closeModal()}><span>{"x"}</span>
                    </div>
                        <div
                            className={className}
                            dangerouslySetInnerHTML={{__html: notificationOverlayContent }}
                        >
                        </div>                        
                        {ctaLabel && <div className="ob-cookies-overlay-btn"><button aria-label={ctaLabel} onClick={() => closeModal()} className="event_button_click">{ctaLabel}</button></div> }
                        </div>
            </Modal> }       
        </div>        
    );
}

export default CookiesNotificationOverlay;

CookiesNotificationOverlay.propTypes = {
    document: PropTypes.object
};